import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import virtualProspectus from "../../static/images/logos/virtualProspectus.svg";
import styles from "./modalCoinSelect.module.scss";

function ChartFrequencySelectModal({ frequency, setFrequency, onClose }) {
  const [searchStr, setSearchStr] = useState("");
  return (
    <>
      <div className={styles.modalCountrySelect}>
        <div className={styles.overlayClose} onClick={() => onClose()} />
        <div className={styles.modalContent}>
          <div className={styles.head}>
            <img src={virtualProspectus} alt="" />
          </div>
          <div className={styles.content}>
            <input
              value={searchStr}
              type="text"
              placeholder={`Search Data Types.....|`}
              className={styles.searchCountry}
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <Scrollbars
              className={styles.countryListScroll}
              renderThumbHorizontal={() => <div />}
              renderThumbVertical={() => <div />}
              renderView={(props) => (
                <div {...props} className={styles.countryList} />
              )}
            >
              <div
                className={`${styles.listCountry} ${styles.true}`}
                onClick={() => {
                  setFrequency("Days");
                  onClose();
                }}
              >
                <div className={styles.name}>Days</div>
              </div>
              <div
                className={`${styles.listCountry} ${styles.dis}`}
                onClick={() => {
                  setFrequency("Days");
                  onClose();
                }}
              >
                <div className={styles.name}>Weeks</div>
              </div>
              <div
                className={`${styles.listCountry} ${styles.dis}`}
                onClick={() => {
                  setFrequency("Days");
                  onClose();
                }}
              >
                <div className={styles.name}>Months</div>
              </div>
              <div
                className={`${styles.listCountry} ${styles.dis}`}
                onClick={() => {
                  setFrequency("Days");
                  onClose();
                }}
              >
                <div className={styles.name}>Quarters</div>
              </div>
            </Scrollbars>
          </div>
          <div className={styles.footer}>
            <div
              className={`${styles.btnType} `}
              onClick={() => {
                onClose();
              }}
            >
              Close
            </div>
            <div
              className={`${styles.btnType} ${styles.true}`}
              onClick={() => {
                onClose();
              }}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartFrequencySelectModal;
