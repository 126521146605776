import React, { Fragment, useRef, useState } from 'react'
import { ReactComponent as SendChat } from '../../images/sendChat.svg'
import { ReactComponent as CloudUpload } from '../../images/cloudUpload.svg'
import FilePreviewModal from './FilePreviewModal'

import styles from './chatInputArea.module.scss'

const ChatInputArea = ({
  onTypingHandler,
  onSubmit,
  isFileLoading,
  initiateSendImage,
  disabledFilePicker
}) => {
  const [chatInput, setChatInput] = useState('')
  const [fileToUpload, setFileToUpload] = useState('')

  const textRef = useRef()

  const onChangeHandler = (e) => {
    const { target } = e
    textRef.current.style.height = '60px'
    textRef.current.style.height = `${target.scrollHeight}px`
  }

  const pasteImageHandler = (event) => {
    let items = [
      ...(event.clipboardData || event.originalEvent.clipboardData).items
    ]
    items = items.filter((i) => /image/.test(i.type))
    let item = items[0]
    if (item && item.kind && item.kind === 'file') {
      let blob = item.getAsFile()
      let reader = new FileReader()
      setFileToUpload(blob)
    }
  }

  const uploadSuccessCallback = () => {
    setFileToUpload('')
    setChatInput('')
  }

  return (
    <Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(chatInput, setChatInput)
        }}
        className={styles.chatInputs}
      >
        {disabledFilePicker || (
          <label className={styles.addFile}>
            <CloudUpload />
            <input
              type='file'
              className={styles.dNone}
              onChange={(e) => {
                setFileToUpload(e.target.files[0])
              }}
            />
          </label>
        )}

        <input
          ref={textRef}
          value={chatInput}
          onPaste={pasteImageHandler}
          onChange={(e) => {
            onChangeHandler(e)
            setChatInput(e.target.value)
            onTypingHandler()
          }}
          className={styles.textArea}
          placeholder='Type Your Message...'
        />
        <button type='submit' className={styles.sendBtn}>
          <SendChat />
        </button>
      </form>
      {fileToUpload && (
        <FilePreviewModal
          isFileLoading={isFileLoading}
          chatInput={chatInput}
          setChatInput={setChatInput}
          fileToUpload={fileToUpload}
          onClose={() => setFileToUpload('')}
          onSubmit={() =>
            initiateSendImage(fileToUpload, chatInput, uploadSuccessCallback)
          }
        />
      )}
    </Fragment>
  )
}

export default ChatInputArea
