import React from 'react'
import styles from './loadingAnim.module.scss'

function LoadingAnim() {
  return (
    <div className={styles.ldsGrid}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default LoadingAnim
