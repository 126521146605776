import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MainLayout } from "../components/FullPageSearch/index";
import Scrollbars from "react-custom-scrollbars";
import Axios from "axios";

import ChatsIoComponentM from "../components/ChatsIoComponent/ChatsIoComponent";
import investmentsGroup from "../static/images/logos/investmentsGroup.svg";
import logo from "../static/images/logos/virtualProspectus.svg";
import logoIcon from "../static/images/logos/vpIcon.svg";
import { ChatsContext } from "../context/ChatsContext";
import CoinListHome from "../components/CoinListHome/CoinListHome";
import SidebarHome from "../components/SidebarHome/SidebarHome";
import { MainContext } from "../context/MainContext";
import FullPageLoader from "../components/FullPageLoader/FullPageLoader";
import MainNavbar from "../layout/MainNavbar/MainNavbar";
import useWindowDimensions from "../Utils/WindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import SettingsSelectModal from "../components/SettingsSelectModal/SettingsSelectModal";

function HomePage() {
  const history = useHistory();
  const { chatOn, setChatOn } = useContext(ChatsContext);
  const { width } = useWindowDimensions();
  const [openFooter, setOpenFooter] = useState(true);
  const [typesList, setTypesList] = useState([]);
  const [pathType, setPathType] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [subTypesList, setSubTypesList] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/investment/types/get")
      .then(({ data }) => {
        if (data.status) {
          setTypesList(data.investments);
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }, []);

  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/coin/investment/sub/types/get?parent_investment_type=${selectedType?.code}`
    ).then(({ data }) => {
      if (data.status) {
        setSubTypesList(data.investmentSubTypes);
        // data.investmentSubTypes[0] &&
        //   setSelectedSubType(data.investmentSubTypes[0].code);
      }
    });
  }, [selectedType]);

  const { tokenSelected, setTokenSelected, mainMenuShow, subMenuShow } =
    useContext(MainContext);
  const [settingsModal, setSettingsModal] = useState(false);

  return (
    <MainLayout
      className="landingPage"
      chatOn={chatOn}
      chatComponent={<ChatsIoComponentM />}
    >
      <MainNavbar
        className=""
        logo={width < 768 ? logoIcon : logo}
        onLogoClick={() => history.push("/")}
        chatOn={chatOn}
        setChatOn={setChatOn}
        btIcon={investmentsGroup}
        onBtClick={() => {
          window.open("https://investments.group/", "_blank").focus();
        }}
        btLabel="Investments.Group"
      />
      {mainMenuShow && (
        <Scrollbars className="topBar">
          <div
            key="all"
            className={`tab ${!selectedType?.code && !pathType}`}
            onClick={() => {
              setSelectedType();
              setPathType("");
            }}
          >
            All
          </div>
          <div
            key="pt"
            className={`tab ${pathType === "shareToken"}`}
            onClick={() => {
              setPathType("shareToken");
              setSelectedType("");
            }}
          >
            Shares
          </div>
          {typesList?.map((type) => (
            <div
              key={type._id}
              className={`tab ${type.code === selectedType?.code}`}
              onClick={() => {
                setPathType("");
                setSelectedType(type);
              }}
            >
              {type.name}
            </div>
          ))}
        </Scrollbars>
      )}
      {selectedType?.code && subMenuShow && (
        <Scrollbars className="subBar">
          <div
            key="all"
            className={`tab ${!selectedSubType?.code}`}
            onClick={() => setSelectedSubType()}
          >
            All {selectedType?.name}
          </div>
          {subTypesList?.map((type) => (
            <div
              key={type._id}
              className={`tab ${type.code === selectedSubType?.code}`}
              onClick={() => setSelectedSubType(type)}
            >
              {type.name}
            </div>
          ))}
        </Scrollbars>
      )}
      <div className="content">
        <CoinListHome
          investmentType={selectedType}
          investmentSubType={selectedSubType}
          tokenSelected={tokenSelected}
          pathType={pathType}
          setTokenSelected={setTokenSelected}
        />
        <SidebarHome tokenSelected={tokenSelected} />
      </div>
      {openFooter && (
        <div className="footerBtn">
          <div className="text">Create Your Own Virtual Prospectus</div>
          <div className="closeBtn" onClick={() => setOpenFooter(false)}>
            &#10005;
          </div>
        </div>
      )}
      {loading && <FullPageLoader />}
      <div className="btnMenu" onClick={() => setSettingsModal(true)}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
      {settingsModal && (
        <SettingsSelectModal onClose={() => setSettingsModal(false)} />
      )}
    </MainLayout>
  );
}

export default HomePage;
