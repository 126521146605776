import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import VaultsSidebar from "./VaultsSidebar";
import { FormatCurrency } from "../../../Utils/Functions";
import { MainContext } from "../../../context/MainContext";

function VirtualProspectusVaults() {
  const { tokenSelected } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [coinSelected, setCoinSelected] = useState();
  const [search, setSearch] = useState("");
  const [isVault, setIsVault] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://api.terminals.dev/vault-set/balance?vaultSet=${tokenSelected?.path_id}&includeTotalUSD=true&email=${tokenSelected?.path_created_by}`
      )
      .then(({ data }) => {
        setCoinList(data.wallet);
      })
      .finally(() => setLoading(false));
  }, [tokenSelected?.path_id, tokenSelected?.path_created_by]);
  return (
    <div className="virtualProspectusVaults">
      <div className="assetList">
        <div className="head">
          <div className="asset">Asset</div>
          <div className="balance">Balance</div>
          <div className="ch24">24Hr</div>
          <div className="usd">USD</div>
        </div>
        <Scrollbars className="coinList" autoHide>
          {loading
            ? Array(8)
                .fill("")
                .map((_, i) => (
                  <div className="coinItem true">
                    <div className="asset">
                      <Skeleton circle className="coin" />
                      <div className="nameDetail">
                        <Skeleton className="name" width={1050} />
                        <Skeleton className="symbol" width={500} />
                      </div>
                    </div>
                  </div>
                ))
            : coinList
                ?.filter(
                  (coin) =>
                    coin?.ter_coin?.coin_code
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    coin?.ter_coin?.coin_name
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    !isVault
                )
                .map((coin) => (
                  <div
                    className={`coinItem ${!coinSelected && true} ${
                      coin === coinSelected && "select"
                    }`}
                    key={coin.id}
                    onClick={() => setCoinSelected(coin)}
                  >
                    <div className="asset">
                      <img
                        src={coin?.ter_coin?.image}
                        alt=""
                        className="coin"
                      />
                      <div className="nameDetail">
                        <div className="name">{coin?.ter_coin?.coin_name}</div>
                        <div className="symbol">
                          {coin?.ter_coin?.coin_code}
                        </div>
                      </div>
                    </div>
                    <div className="balance">
                      {FormatCurrency(coin.balance, coin?.ter_coin?.coin_code)}
                    </div>
                    <div className="ch24">{FormatCurrency()}%</div>
                    <div className="usd">
                      {FormatCurrency(coin.usdValue, coin?.ter_coin?.coin_code)}
                    </div>
                  </div>
                ))}
        </Scrollbars>
      </div>
      <VaultsSidebar
        coinSelected={coinSelected}
        search={search}
        setSearch={setSearch}
        isVault={isVault}
        setIsVault={setIsVault}
      />
    </div>
  );
}

export default VirtualProspectusVaults;
