import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import useWindowDimensions from "../Utils/WindowSize";

export const MainContext = createContext();
function MainContextProvider({ children }) {
  const [coinList, setCoinList] = useState([]);
  const [coinLoading, setCoinLoading] = useState(false);
  const [coinSelected, setCoinSelected] = useState();
  const [mainMenuShow, setMainMenuShow] = useState(true);
  const [subMenuShow, setSubMenuShow] = useState(true);
  const [bottomShow, setBottomShow] = useState(true);
  const [tokenSelected, setTokenSelected] = useState();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 768) {
      setMainMenuShow(false);
      setSubMenuShow(false);
      setBottomShow(false);
    }
  }, [width]);

  useEffect(() => {
    setCoinLoading(true);
    axios
      .get("https://comms.globalxchange.io/coin/vault/get/all/coins")
      .then(({ data }) => {
        if (data.status) {
          setCoinList(data.coins);
          setCoinSelected(data.coins[0]);
        }
      })
      .finally(() => setCoinLoading(false));
  }, []);

  useEffect(() => {
    const coin = coinList.filter(
      (coin) => coin.coinSymbol === tokenSelected?.asset
    )[0];
    setCoinSelected({ ...coin, default: true });
  }, [coinList, setCoinSelected, tokenSelected]);

  const [coinsObject, setCoinsObject] = useState();
  useEffect(() => {
    const obj = {};
    coinList.forEach((coin) => {
      obj[coin.coinSymbol] = coin;
    });
    setCoinsObject(obj);
  }, [coinList]);

  return (
    <MainContext.Provider
      value={{
        coinsObject,
        coinList,
        coinLoading,
        coinSelected,
        setCoinSelected,
        mainMenuShow,
        setMainMenuShow,
        subMenuShow,
        setSubMenuShow,
        bottomShow,
        setBottomShow,
        tokenSelected,
        setTokenSelected,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export default MainContextProvider;
