import React, { useContext, useEffect, useState } from 'react'

import styles from './searchView.module.scss'
import { OneOneChatContext } from '../../contexts/OneOneChatContext'

function SearchView({ setTab, search }) {
  const { recentList, setSelectedUser } = useContext(OneOneChatContext)
  const [friendsList, setFriendsList] = useState([])
  useEffect(() => {
    const userList = []
    recentList &&
      recentList.forEach((user) => {
        userList.push({
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          avatar: user.avatar,
          onClick: () => {
            setTab('chat')
            setSelectedUser(user)
          }
        })
      })
    setFriendsList(userList)
  }, [recentList])
  return (
    <div className={styles.searchView}>
      <div className={styles.friendsListContainer}>
        {friendsList
          .filter((friend) =>
            friend.name?.toLowerCase().includes(search.toLowerCase())
          )
          .map((friend) => (
            <div
              className={styles.friendItem}
              key={friend.id}
              onClick={() => {
                try {
                  friend.onClick()
                } catch (error) {}
              }}
            >
              <img src={friend.avatar} alt='' className={styles.avatarImage} />
              <div className={styles.wrapper}>
                <div className={styles.name}>{friend.name}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SearchView
