import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import virtualProspectus from "../../static/images/logos/virtualProspectus.svg";
import styles from "./modalCoinSelect.module.scss";

const CHART_TYPES = [
  {
    name: "Candle Stick",
    active: true,
  },
  {
    name: "Line Chart",
    active: true,
  },
  {
    name: "Bar Chart",
    active: false,
  },
  {
    name: "Tick Chart",
    active: false,
  },
  {
    name: "Renko Chart",
    active: false,
  },
  {
    name: "Kagi Chart",
    active: false,
  },
  {
    name: "Range Bar Chart",
    active: false,
  },
  {
    name: "Three-Line Break Chart",
    active: false,
  },
];

function ChartSettingsModal({ onClose, chartType, setChartType }) {
  const [step, setStep] = useState();

  function getContent() {
    switch (step) {
      case "Chart Type":
        return (
          <Scrollbars
            className={styles.countryListScroll}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
            renderView={(props) => (
              <div {...props} className={styles.countryList} />
            )}
          >
            {CHART_TYPES.map((chart) => (
              <div
                key={chart.name}
                className={`${styles.listCountry} ${
                  chartType === chart.name && styles.true
                } ${!chart.active && styles.dis}`}
                onClick={() => {
                  setChartType(chart.name);
                  onClose();
                }}
              >
                <div className={styles.name}>{chart.name}</div>
              </div>
            ))}
          </Scrollbars>
        );

      default:
        return (
          <Scrollbars className={styles.mainMenu}>
            <div className={styles.card} onClick={() => setStep("Chart Type")}>
              <div className={styles.texts}>
                <div className={styles.text}>Chart Type</div>
                <div className={styles.subText}>{chartType}</div>
              </div>
              <div className={styles.imgWrap}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.faIcon}
                />
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.texts}>
                <div className={styles.text}>X Axis Size</div>
                <div className={styles.subText}>12 Units</div>
              </div>
              <div className={styles.imgWrap}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.faIcon}
                />
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.texts}>
                <div className={styles.text}>Y Axis Size</div>
                <div className={styles.subText}>Auto</div>
              </div>
              <div className={styles.imgWrap}>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.faIcon}
                />
              </div>
            </div>
          </Scrollbars>
        );
    }
  }

  return (
    <>
      <div className={styles.modalCountrySelect}>
        <div className={styles.overlayClose} onClick={() => onClose()} />
        <div className={styles.modalContent}>
          <div className={styles.head}>
            <img src={virtualProspectus} alt="" />
          </div>
          <div className={styles.headerTitle}>
            <div className={styles.title}>Chart Settings</div>
            <div className={styles.breadCrumbs}>
              <span onClick={() => setStep("")}>Settings</span>
              {step && (
                <>
                  ➔<span>{step}</span>
                </>
              )}
            </div>
            <div className={styles.closeBt} onClick={() => onClose()}>
              ✕
            </div>
          </div>
          {getContent()}
          <div className={styles.footer}>
            <div
              className={styles.btnType}
              onClick={() => {
                onClose();
              }}
            >
              Close
            </div>
            <div
              className={`${styles.btnType} ${styles.true}`}
              onClick={() => {
                onClose();
              }}
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartSettingsModal;
