import React, { useContext, useEffect, useState } from "react";
import { FormatCurrency } from "../../Utils/Functions";
import Scrollbars from "react-custom-scrollbars";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { MainContext } from "../../context/MainContext";
import CoinSelectModal from "../CoinSelectModal/CoinSelectModal";

function TokenDetail({ tokenSelected }) {
  const { coinList, coinSelected, setCoinSelected } = useContext(MainContext);
  const [tokenHolders, setTokenHolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assetCoin, setAssetCoin] = useState();
  const [coinModal, setCoinModal] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?path_id=${tokenSelected?.path_id}&getTokenHolders=true`
      )
      .then(({ data }) => {
        if (data.status) {
          setTokenHolders(data.tokenHolders);
        }
      })
      .finally(() => setLoading(false));
  }, [tokenSelected]);

  useEffect(() => {
    const coin = coinList.filter(
      (coin) => coin.coinSymbol === tokenSelected?.asset
    )[0];
    setAssetCoin(coin);
  }, [coinList, setCoinSelected, tokenSelected]);

  return (
    <>
      <div className="tokenDetail">
        <div className="tokHead">
          <img
            className="logo"
            src={tokenSelected?.token_profile_data?.coinImage}
            alt=""
          />
          <div className="tokenDetail">
            <div className="name">
              {tokenSelected?.token_profile_data?.coinName}
            </div>
            <div className="createdBy">
              Created By <span>{tokenSelected?.banker}</span>
            </div>
          </div>
        </div>
        <div className="title">
          <div className="titleText">Issuance Currency</div>
          <div className="titleText coinBt" onClick={() => setCoinModal(true)}>
            <img src={coinSelected?.coinImage} alt="" />
            {coinSelected?.coinSymbol}
          </div>
        </div>
        <p className="desc">
          {tokenSelected?.token_profile_data?.coinDescription}
        </p>
        <div className="values">
          <div className="card">
            <div className="label">Market Cap</div>
            <div className="value">
              {FormatCurrency(
                (tokenSelected?.asset_balance * assetCoin?.usd_price) /
                  coinSelected?.usd_price,
                coinSelected?.coinSymbol
              )}
            </div>
          </div>
          <div className="card">
            <div className="label">Token Supply</div>
            <div className="value">
              {FormatCurrency(tokenSelected?.circulating_supply)}
            </div>
          </div>
          <div className="card">
            <div className="label">{tokenSelected?.token} Price</div>
            <div className="value">
              {FormatCurrency(
                (tokenSelected?.token_price * assetCoin?.usd_price) /
                  coinSelected?.usd_price,
                coinSelected?.coinSymbol
              )}
            </div>
          </div>
        </div>
        <div className="headInv">Notable Investors</div>
        <Scrollbars className="investorsList">
          {loading
            ? Array(5)
                .fill("")
                .map((_, i) => (
                  <div className="investor">
                    <Skeleton circle className="dp" />
                    <div className="userDetail">
                      <Skeleton className="name" width={200} />
                      <Skeleton className="email" width={300} />
                    </div>
                  </div>
                ))
            : tokenHolders.slice(0, 5).map((user, i) => (
                <div className="investor">
                  <img
                    src={`https://avatars.dicebear.com/api/avataaars/${i}.svg`}
                    alt=""
                    className="dp"
                  />
                  <div className="userDetail">
                    <div className="name">
                      <span>{user.name}</span>
                      <span>
                        {FormatCurrency(user.percentage_of_tokensSold)}%
                      </span>
                    </div>
                    <div className="email">
                      <span>{user.email}</span>
                      <span>
                        {FormatCurrency(user.token_balance)}
                        {tokenSelected?.token}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
        </Scrollbars>
      </div>
      {coinModal && (
        <CoinSelectModal
          setCoin={setCoinSelected}
          onClose={() => setCoinModal(false)}
        />
      )}
    </>
  );
}

export default TokenDetail;
