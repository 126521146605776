import React from 'react'
import useWindowDimensions from '../../Utils/WindowSize'
import style from './mainLayout.scss'

function MainLayout({ children, chatOn, chatComponent }) {
  const { height } = useWindowDimensions()
  return (
    <div className={`mainLayout ${style.mainLayout}`} style={{ height }}>
      <div className={`mainArea ${style.mainArea} ${chatOn}`}>{children}</div>
      {chatOn && chatComponent}
    </div>
  )
}

export default MainLayout
