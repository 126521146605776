import React, { createContext, useEffect, useState } from 'react'
import Axios from 'axios'

export const MainContext = createContext()

function MainContextProvider({
  children,
  emailId,
  idToken,
  logo,
  botLogo,
  appCode
}) {
  const [email, setEmail] = useState(emailId)
  const [token, setToken] = useState(idToken)
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [profileImg, setProfileImg] = useState('')
  function login(email, token) {
    setEmail(email)
    setToken(token)
  }
  useEffect(() => {
    Axios.post('https://comms.globalxchange.io/get_affiliate_data_no_logs', {
      email: email
    }).then((res) => {
      const data = res.data[0]
      if (data) {
        setUsername(data.username)
        setName(data.name)
        setProfileImg(data.profile_img)
      }
    })
  }, [email])
  return (
    <MainContext.Provider
      value={{
        username,
        name,
        profileImg,
        logo,
        botLogo,
        appCode,
        login,
        email,
        token
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export default MainContextProvider
