import React from "react";
import Scrollbars from "react-custom-scrollbars";

import ctrlIcon from "../../../static/images/icons/ctrl.svg";
import user from "../../../static/images/icons/user.svg";
import time from "../../../static/images/icons/time.svg";
import tradingFee from "../../../static/images/icons/tradingFee.svg";
import intrest from "../../../static/images/icons/intrest.svg";
import custom from "../../../static/images/icons/custom.svg";

function VirtualProspectusBusiness() {
  return (
    <div className="virtualProspectusBusiness">
      <div className="userProduct">
        <div className="tabsUser">
          <div className="tab true">By User</div>
          <div className="tab">By Product</div>
        </div>
        <div className="selected">
          <div className="currentlySelected">
            Currently Selected
            <span>All Users</span>
          </div>
          <div className="user">
            <img src="" alt="" />
            <div className="userDetail">
              <div className="name">All Users</div>
              <div className="email">835 Users</div>
            </div>
          </div>
        </div>
        <label className="searchUser">
          <input type="text" placeholder="Search By Email" />
        </label>
        <Scrollbars className="userList">
          {Array(10)
            .fill("")
            .map((_, i) => (
              <div className="user" key={i}>
                <img src="" alt="" />
                <div className="userDetail">
                  <div className="name">John Adams</div>
                  <div className="email">shorupan@gmail.com</div>
                </div>
              </div>
            ))}
        </Scrollbars>
      </div>
      <div className="details">
        <div className="topCards">
          <div className="users">92 Users</div>
          <div className="revenue">$1,351,352.24 Revenue</div>
          <div className="customers">1,333 Customers</div>
        </div>
        <div className="secondCards">
          <div className="users">
            <div className="value">$3,135.45</div>
            <div className="label">10 Deposits</div>
          </div>
          <div className="revenue">
            <div className="value">$3,135.45</div>
            <div className="label">Trading Revenue</div>
          </div>
          <div className="revenue">
            <div className="value">$3,135.45</div>
            <div className="label">Interest Differential</div>
          </div>
          <div className="revenue">
            <div className="value">$3,135.45</div>
            <div className="label">Product Sales</div>
          </div>
          <div className="customers">
            <div className="value">$3,135.45</div>
            <div className="label">19 Withdrawals</div>
          </div>
        </div>
        <div className="tableWrap">
          <div className="ctrlHead">
            <div className="conroller">
              <div className="switch true">
                <div className="ball" />
              </div>
              <span>Controller</span>
            </div>
            <input type="text" placeholder="Search Sources Of Revenue.....|" />
            <img src={ctrlIcon} alt="" />
            <img src={user} alt="" />
            <img src={time} alt="" />
          </div>
          <div className="tableHid">
            <table className="tableUsers">
              <thead>
                <tr>
                  <th>Revenue Type</th>
                  <th>Earnings</th>
                  <th>% Of Total</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="type">
                      <img src={tradingFee} alt="" />
                      Trading Fees
                    </div>
                  </td>
                  <td>$23,352.35</td>
                  <td>12.35%</td>
                  <td>14 Trades</td>
                </tr>
                <tr>
                  <td>
                    <div className="type">
                      <img src={intrest} alt="" />
                      Interest Rate Differential
                    </div>
                  </td>
                  <td>$23,352.35</td>
                  <td>12.35%</td>
                  <td>14 Interest Payments</td>
                </tr>
                <tr>
                  <td>
                    <div className="type">
                      <img src={custom} alt="" />
                      Custom Investment Products
                    </div>
                  </td>
                  <td>$23,352.35</td>
                  <td>12.35%</td>
                  <td>14 Sales</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualProspectusBusiness;
