import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MainContext } from "../../context/MainContext";
import virtualProspectus from "../../static/images/logos/virtualProspectus.svg";
import styles from "./modalCoinSelect.module.scss";

function SettingsSelectModal({ onClose }) {
  const {
    coinList,
    coinLoading,
    coinSelected,
    setCoinSelected,
    mainMenuShow,
    setMainMenuShow,
    subMenuShow,
    setSubMenuShow,
    bottomShow,
    setBottomShow,
  } = useContext(MainContext);
  const [searchStr, setSearchStr] = useState("");
  const [type, setType] = useState("fiat");
  const [conSelect, setConSelect] = useState(false);
  const [mainMnuShow, setMainMnuShow] = useState(mainMenuShow);
  const [subShow, setSubShow] = useState(subMenuShow);
  const [btmShow, setBtmShow] = useState(bottomShow);

  return (
    <>
      <div className={styles.modalCountrySelect}>
        <div className={styles.overlayClose} onClick={() => onClose()} />
        <div className={styles.modalContent}>
          <div className={styles.head}>
            <img src={virtualProspectus} alt="" />
          </div>
          <div className={styles.headerTitle}>
            <div className={styles.title}>Settings</div>
            <div className={styles.breadCrumbs}>
              <span onClick={() => setConSelect(false)}>Settings</span>
              {conSelect && (
                <>
                  ➔<span>Change Display Currency</span>
                </>
              )}
            </div>
            <div className={styles.closeBt} onClick={() => onClose()}>
              ✕
            </div>
          </div>
          {conSelect ? (
            <>
              <div className={styles.content}>
                <input
                  value={searchStr}
                  type="text"
                  placeholder={`Search ${type} Currencies...|`}
                  className={styles.searchCountry}
                  onChange={(e) => setSearchStr(e.target.value)}
                />
                <Scrollbars
                  className={styles.countryListScroll}
                  renderThumbHorizontal={() => <div />}
                  renderThumbVertical={() => <div />}
                  renderView={(props) => (
                    <div {...props} className={styles.countryList} />
                  )}
                >
                  {coinLoading
                    ? Array(6)
                        .fill("")
                        .map((coin) => (
                          <div className={styles.listCountry}>
                            <Skeleton className={styles.name} width={200} />
                            <Skeleton circle className={styles.flag} />
                          </div>
                        ))
                    : coinList
                        .filter((coin) => coin.type === type)
                        .filter(
                          (coin) =>
                            coin.coinSymbol
                              .toLowerCase()
                              .includes(searchStr.toLowerCase()) ||
                            coin.coinName
                              .toLowerCase()
                              .includes(searchStr.toLowerCase())
                        )
                        .map((coin) => (
                          <div
                            key={coin.coinSymbol}
                            className={`${styles.listCountry} ${
                              coinSelected?.coinSymbol === coin.coinSymbol &&
                              styles.true
                            }`}
                            onClick={() => {
                              setCoinSelected(coin);
                              onClose();
                            }}
                          >
                            <div className={styles.name}>
                              {coin.coinName}({coin.coinSymbol})
                            </div>
                            <img
                              src={coin.coinImage}
                              alt=""
                              className={styles.flag}
                            />
                          </div>
                        ))}
                </Scrollbars>
              </div>
              <div className={styles.footer}>
                <div
                  className={`${styles.btnType} ${
                    type === "fiat" && styles.true
                  }`}
                  onClick={() => setType("fiat")}
                >
                  Fiat
                </div>
                <div
                  className={`${styles.btnType} ${
                    type === "crypto" && styles.true
                  }`}
                  onClick={() => setType("crypto")}
                >
                  Crypto
                </div>
              </div>
            </>
          ) : (
            <>
              <Scrollbars className={styles.mainMenu}>
                <div className={styles.card}>
                  <div className={styles.texts}>
                    <div className={styles.text}>Main Menu Bar</div>
                    <div className={styles.subText}>
                      Currently {mainMenuShow ? "Enabled" : "Disabled"}
                    </div>
                  </div>
                  <div className={styles.imgWrap}>
                    <div
                      className={styles.switch}
                      onClick={() => setMainMnuShow(!mainMnuShow)}
                    >
                      <div
                        className={`${styles.ball} ${
                          mainMnuShow && styles.true
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.texts}>
                    <div className={styles.text}>Sub Menu Bar</div>
                    <div className={styles.subText}>
                      Currently {subMenuShow ? "Enabled" : "Disabled"}
                    </div>
                  </div>
                  <div className={styles.imgWrap}>
                    <div
                      className={styles.switch}
                      onClick={() => setSubShow(!subShow)}
                    >
                      <div
                        className={`${styles.ball} ${subShow && styles.true}`}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.card} onClick={() => setConSelect(true)}>
                  <div className={styles.texts}>
                    <div className={styles.text}>Display Currency</div>
                    <div className={styles.subText}>
                      Currently {coinSelected?.coinSymbol}
                    </div>
                  </div>
                  <div className={styles.imgWrap}>
                    <img
                      src={coinSelected?.coinImage}
                      alt=""
                      className={styles.icon}
                    />
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.texts}>
                    <div className={styles.text}>Bottom Menu Bar</div>
                    <div className={styles.subText}>
                      Currently {bottomShow ? "Enabled" : "Disabled"}
                    </div>
                  </div>
                  <div className={styles.imgWrap}>
                    <div
                      className={styles.switch}
                      onClick={() => setBtmShow(!btmShow)}
                    >
                      <div
                        className={`${styles.ball} ${btmShow && styles.true}`}
                      />
                    </div>
                  </div>
                </div>
              </Scrollbars>
              <div className={styles.footer}>
                <div className={styles.btnType}>Share</div>
                <div
                  className={`${styles.btnType} ${styles.true}`}
                  onClick={() => {
                    setSubMenuShow(subShow);
                    setBottomShow(btmShow);
                    setMainMenuShow(mainMnuShow);
                    onClose();
                  }}
                >
                  Save
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SettingsSelectModal;
