import React from 'react'
import MainLayoutComponent from './components/ChatLayout/MainLayout'
import SearchbarComponent from './components/Searchbar'
import SearchComponent from './components/SearchComponent'
import SearchNavbarComponent from './components/SearchNavbar'
import SearchBarSingleComponent from './components/SerchBarSingle'
import SearchViewComponent from './components/SearchView'
import CategoryModalComponent from './components/CategoryModal/CategoryModal'
import MainNavbarComponent from './components/MainNavbar/MainNavbar'
import FABComponent from './components/FAB/FAB'

export const Searchbar = ({
  search,
  setSearch,
  placeholder,
  iconOne,
  iconTwo
}) => {
  return (
    <SearchbarComponent
      search={search}
      setSearch={setSearch}
      placeholder={placeholder}
      iconOne={iconOne}
      iconTwo={iconTwo}
    />
  )
}

export const SearchBarSingle = ({
  search,
  setSearch,
  placeholder,
  iconOne,
  onClick
}) => {
  return (
    <SearchBarSingleComponent
      search={search}
      setSearch={setSearch}
      placeholder={placeholder}
      iconOne={iconOne}
      onClick={onClick}
    />
  )
}

export const SearchView = ({
  search,
  setSearch,
  loading,
  appLogo,
  searchList,
  goBack,
  iconOne,
  iconTwo,
  placeholder
}) => {
  return (
    <SearchViewComponent
      search={search}
      setSearch={setSearch}
      placeholder={placeholder}
      iconOne={iconOne}
      iconTwo={iconTwo}
      loading={loading}
      appLogo={appLogo}
      searchList={searchList}
      goBack={goBack}
    />
  )
}

export const SearchNavbar = ({
  appLogo,
  iconOne,
  iconTwo,
  search,
  setSearch,
  onClick,
  placeholder
}) => {
  return (
    <SearchNavbarComponent
      appLogo={appLogo}
      iconOne={iconOne}
      iconTwo={iconTwo}
      search={search}
      setSearch={setSearch}
      placeholder={placeholder}
      onClick={onClick}
    />
  )
}

export const SearchViewMain = ({
  search,
  setSearch,
  loading,
  appLogo,
  searchList,
  iconOne,
  iconTwo,
  placeholder
}) => {
  return (
    <SearchComponent
      search={search}
      setSearch={setSearch}
      placeholder={placeholder}
      iconOne={iconOne}
      iconTwo={iconTwo}
      loading={loading}
      appLogo={appLogo}
      searchList={searchList}
    />
  )
}

export const MainLayout = ({ children, chatOn, chatComponent }) => {
  return (
    <MainLayoutComponent
      children={children}
      chatOn={chatOn}
      chatComponent={chatComponent}
    />
  )
}

export const MainNavbar = ({
  className,
  logo,
  onLogoClick,
  chatOn,
  setChatOn,
  btIcon,
  onBtClick,
  btLabel
}) => {
  return (
    <MainNavbarComponent
      className={className}
      logo={logo}
      onLogoClick={onLogoClick}
      chatOn={chatOn}
      setChatOn={setChatOn}
      btIcon={btIcon}
      onBtClick={onBtClick}
      btLabel={btLabel}
    />
  )
}

export const CategoryModal = ({ categories, onClose, label }) => {
  return (
    <CategoryModalComponent
      categories={categories}
      onClose={onClose}
      label={label}
    />
  )
}

export const FAB = () => {
  return <FABComponent />
}

export default SearchViewMain
