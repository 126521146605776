import React, { Fragment, useContext, useRef, useState } from 'react'
import S3FileUpload from 'react-s3'
import { ReactComponent as SendChat } from '../../../images/sendChat.svg'
import { ReactComponent as CloudUpload } from '../../../images/cloudUpload.svg'
import FilePreviewModal from './FilePreviewModal'
import { SupportChatContext } from '../../../contexts/SupportChatContext'
import styles from '../supportChat.module.scss'

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified
  })
}

function ChatInputs() {
  const {
    handleSubmitMessage,
    threadId,
    chatUserObject,
    socketRef,
    setMessageArray,
    messageArray
  } = useContext(SupportChatContext)
  const onTypingHandler = () =>
    socketRef?.current?.emit('typing', chatUserObject.username, threadId)

  const textRef = useRef()
  const [chatInput, setChatInput] = useState('')

  const onChangeHandler = function (e) {
    const { target } = e
    textRef.current.style.height = '60px'
    textRef.current.style.height = `${target.scrollHeight}px`
  }

  const [isFileLoading, setIsFileLoading] = useState(false)
  const [fileToUpload, setFileToUpload] = useState('')
  const initiateSendImage = async (e) => {
    setIsFileLoading(true)
    let file = fileToUpload
    if (file) {
      try {
        setIsFileLoading(true)
        const fileName = file.name.replace(' ', '_')
        const fullFileName = `${Date.now().toString()}${fileName}`
        file = renameFile(file, fullFileName)
        const options = {
          dirName: 'uploads/',
          bucketName: 'chatsgx',
          region: 'us-east-2',
          accessKeyId: 'AKIAQD4QYXWFTC6JX6YM',
          secretAccessKey: '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'
        }

        S3FileUpload.uploadFile(file, options)
          .then((resp) => {
            const { location } = resp
            const inputText = chatInput.trim()
            if (location) {
              let message_data = {
                message: inputText,
                thread_id: threadId,
                sender: chatUserObject.username,
                timestamp: Date.now(),
                filename: fullFileName,
                type: file.type.split('/')[0],
                location: location
              }

              socketRef.current.emit(
                'new_support_group_message',
                JSON.stringify(message_data),
                (response) => {
                  if (response === 'success') {
                    setMessageArray([...messageArray, { ...message_data }])
                    setChatInput('')
                    setFileToUpload('')
                  } else {
                    console.log('Error in sending message')
                  }
                  setIsFileLoading(false)
                }
              )
            }
          })
          .finally(() => setIsFileLoading(false))
      } catch (error) {
        console.log('Error on FileUpload', error)
      }
    }
  }

  function pasteImageHandler(event) {
    let items = [
      ...(event.clipboardData || event.originalEvent.clipboardData).items
    ]
    items = items.filter((i) => /image/.test(i.type))
    let item = items[0]
    if (item && item.kind && item.kind === 'file') {
      let blob = item.getAsFile()
      let reader = new FileReader()
      setFileToUpload(blob)
    }
  }

  return (
    <Fragment>
      <div className={styles.chatInputs}>
        <label className={styles.addFile}>
          <CloudUpload />
          <input
            type='file'
            className={styles.dNone}
            onChange={(e) => {
              setFileToUpload(e.target.files[0])
            }}
          />
        </label>

        <input
          ref={textRef}
          value={chatInput}
          onPaste={pasteImageHandler}
          onChange={(e) => {
            onChangeHandler(e)
            setChatInput(e.target.value)
            onTypingHandler()
          }}
          className={styles.textArea}
        />
        <div
          className={styles.sendBtn}
          onClick={() => handleSubmitMessage(chatInput, setChatInput)}
        >
          <SendChat />
        </div>
      </div>
      {fileToUpload && (
        <FilePreviewModal
          isFileLoading={isFileLoading}
          chatInput={chatInput}
          setChatInput={setChatInput}
          fileToUpload={fileToUpload}
          onClose={() => setFileToUpload('')}
          onSubmit={() => initiateSendImage()}
        />
      )}
    </Fragment>
  )
}

export default ChatInputs
