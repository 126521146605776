import React from 'react'
import { ReactComponent as DocumentIcon } from '../../../images/documents.svg'

import styles from './selectFileModal.module.scss'

function FilePreviewModal({
  chatInput,
  setChatInput,
  fileToUpload,
  onClose,
  onSubmit,
  isFileLoading
}) {
  const getPreview = () => {
    switch (fileToUpload.type.split('/')[0]) {
      case 'image':
        return (
          <img
            className={styles.previewItem}
            src={URL.createObjectURL(fileToUpload)}
            alt=''
          />
        )
      case 'video':
        return (
          <video
            autoPlay={false}
            controls
            className={styles.previewItem}
            src={URL.createObjectURL(fileToUpload)}
            alt=''
          />
        )
      default:
        return (
          <DocumentIcon className={`${styles.previewItem} ${styles.false}`} />
        )
    }
  }
  return (
    <div className={styles.fileModalWrapper}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={styles.selectFileModal}>
        <div className={styles.head}>Upload File</div>
        <div className={styles.preview}>
          <svg viewBox='0 0 16 9' />
          {getPreview()}
        </div>
        <input
          type='text'
          className={styles.textInput}
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          placeholder='Message'
        />
        <div
          className={styles.btnSend}
          onClick={() => !isFileLoading && onSubmit()}
        >
          {isFileLoading ? 'Uploading' : 'Send'}
        </div>
      </div>
    </div>
  )
}

export default FilePreviewModal
