import React, { useContext } from 'react'

import { ReactComponent as FriendsIcon } from '../../images/filterIcons/friends.svg'
import { ReactComponent as ArticlesIcon } from '../../images/filterIcons/articles.svg'
import { ReactComponent as VideosIcon } from '../../images/filterIcons/videos.svg'

import styles from './searchFilter.module.scss'
import { MainContext } from '../../contexts/MainContext'

function SearchFilter({ setfilterBy }) {
  const { email } = useContext(MainContext)
  return (
    <div className={styles.searchFilter}>
      <div className={styles.header}>Featured Sections</div>
      <div
        className={styles.recentChatsContainer}
        onClick={() => setfilterBy('All')}
      >
        <div className={styles.recentChatItem}>
          <svg
            className={styles.avatarImage}
            viewBox='0 0 35 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M28.457 32H34.577L20.357 0.499998H14.597L0.421973 32H6.45197L9.55697 24.71H25.307L28.457 32ZM11.537 20.12L17.432 6.35L23.372 20.12H11.537Z'
              fill='#2B2D33'
            />
          </svg>
          <div className={styles.name}>All</div>
        </div>
      </div>
      <div className={styles.header}>All Categories</div>
      <div className={styles.friendsListContainer}>
        <div
          className={`${styles.friendItem} ${email ? '' : styles.disable}`}
          onClick={() => email && setfilterBy('Friends')}
        >
          <FriendsIcon className={styles.avatarImage} />
          <div className={styles.wrapper}>
            <div className={styles.name}>Friends</div>
          </div>
        </div>
        <div
          className={`${styles.friendItem} ${email ? '' : styles.disable}`}
          onClick={() => email && setfilterBy('Videos')}
        >
          <VideosIcon className={styles.avatarImage} />
          <div className={styles.wrapper}>
            <div className={styles.name}>Videos</div>
          </div>
        </div>
        <div
          className={`${styles.friendItem} ${email ? '' : styles.disable}`}
          onClick={() => email && setfilterBy('Articles')}
        >
          <ArticlesIcon className={styles.avatarImage} />
          <div className={styles.wrapper}>
            <div className={styles.name}>Articles </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchFilter
