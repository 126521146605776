import React, { useContext, useState } from "react";
import ChatsIoComponent from "../ChatsIoPlugin/index";
// import "@teamforce/chats.io-plugin/dist/index.css";

import logo from "../../static/images/logos/virtualProspectus.svg";
import { ChatsContext } from "../../context/ChatsContext";

function ChatsIoComponentM() {
  const { setChatOn } = useContext(ChatsContext);
  // eslint-disable-next-line no-unused-vars
  const [tabSelected, setTabSelected] = useState();
  return (
    <ChatsIoComponent
      tabOpen={tabSelected}
      primaryColor="#002A51"
      secondaryColor="#e7e7e7"
      appCode="ice"
      logo={logo}
      onClose={() => setChatOn(false)}
      right
    />
  );
}

export default ChatsIoComponentM;
