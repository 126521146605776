import React, { Fragment, useContext, useEffect } from 'react';
import moment from 'moment';

import FilePreviewOnChat from './FilePreviewOnChat';
import { SupportChatContext } from '../../../contexts/SupportChatContext';
import { MainContext } from '../../../contexts/MainContext';
import styles from '../supportChat.module.scss';

function ChatArea() {
  const { messageArray, typingFlag, chatUserObject } =
    useContext(SupportChatContext);
  const currentUser = chatUserObject?.username;
  useEffect(() => {
    if (messageArray?.length > 1) {
      const objDiv = document.getElementById('chat-area');
      try {
        objDiv.scrollTop = objDiv.scrollHeight;
      } catch (error) {}
    }
    return () => {};
  }, [messageArray, typingFlag]);
  return (
    <div autoHide className={styles.chatView} id="chat-area">
      {messageArray.map((item) => {
        return item.type !== 'group' ? (
          <Message
            key={item.timestamp}
            time={item.timestamp}
            messageObj={item}
            isSent={currentUser === item.sender}
          />
        ) : (
          <InChatInfo key={item.timestamp} message={item.message} />
        );
      })}
      {typingFlag && <TypingIndicator />}
    </div>
  );
}
const Message = ({ messageObj, isSent }) => {
  const { profileImg, botLogo } = useContext(MainContext);
  return (
    <Fragment>
      {isSent ? (
        <div className={styles.answer}>
          <div className={styles.questionWrap}>
            {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )}
            <p className={styles.questionText}>{messageObj.message}</p>
          </div>
          <div className={styles.bot}>
            <img
              className={styles.imgLogo}
              src={
                profileImg ||
                `https://avatars.dicebear.com/4.5/api/avataaars/${messageObj?.sender?.first_name}.svg`
              }
              alt={messageObj?.sender?.first_name}
            />
            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
        </div>
      ) : (
        <div className={styles.question}>
          <div className={styles.bot}>
            <img
              className={styles.imgLogo}
              src={
                botLogo ||
                `https://avatars.dicebear.com/4.5/api/bottts/${messageObj?.sender?.first_name}.svg`
              }
              alt={messageObj?.sender?.first_name}
            />
            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
          <div className={styles.questionWrap}>
            {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )}
            <p className={styles.questionText}>{messageObj.message}</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const InChatInfo = ({ message }) => {
  return (
    <div className={styles.inChatInfo}>
      <span>{message}</span>
    </div>
  );
};

const TypingIndicator = () => {
  return (
    <div className={styles.question}>
      <div className={styles.bot}>
        {/* <img className={styles.imgLogo} src={assetLogo} alt="" /> */}
        <p>{moment().format('hh:mm A')}</p>
      </div>
      <div className={styles.questionLoading}>
        <div className={styles.ldsEllipsis}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default ChatArea;
