import React from 'react'
import { ReactComponent as DocumentIcon } from '../../../images/documents.svg'
import styles from '../supportChat.module.scss'

function FilePreviewOnChat({ link, type }) {
  const getPreview = () => {
    switch (type) {
      case 'image':
        return <img className={styles.previewItem} src={link} alt='' />
      case 'video':
        return (
          <video
            autoPlay={false}
            controls
            className={styles.previewItem}
            src={link}
            alt=''
          />
        )
      default:
        return (
          <DocumentIcon className={`${styles.previewItem} ${styles.false}`} />
        )
    }
  }
  return (
    <a
      href={link}
      download
      className={styles.preview}
      target='_blank'
      rel='noreferrer'
    >
      <svg viewBox='0 0 16 9' />
      {getPreview()}
    </a>
  )
}

export default FilePreviewOnChat
