import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Logo } from './images/chatsLogo.svg'
import { ReactComponent as SearchIcon } from './images/search.svg'
import { ReactComponent as BackIcon } from './images/back.svg'
import { ReactComponent as ExpandIcon } from './images/expand.svg'

import ChatsIoFooterMenu from './components/ChatsIoFooterMenu/ChatsIoFooterMenu'
import ChatsIoOneOneChat from './components/ChatsIoOneOneChat'
import ChatsIoMenu from './components/ChatsIoMenu/ChatsIoMenu'
import ChatsIoSupport from './components/ChatsIoSupport/ChatsIoSupport'
import SupportChatContextProvider from './contexts/SupportChatContext'
import OneOneChatContextProvider from './contexts/OneOneChatContext'

import styles from './index.module.scss'
import MainContextProvider from './contexts/MainContext'
import InboxSidebar from './components/InboxSidebar/InboxSidebar'
import SearchFilter from './components/SearchFilter/SearchFilter'
import SearchView from './components/SearchView/SearchView'

function ChatsIoComponent({
  tabOpen,
  emailId,
  idToken,
  primaryColor = '#334480',
  secondaryColor = '#555770',
  logo,
  botLogo,
  appCode,
  profileComponent,
  botComponent,
  callComponent,
  onClose,
  right
}) {
  const ref = useRef()
  useEffect(() => {
    ref.current.style.setProperty('--primary-color', primaryColor)
    ref.current.style.setProperty('--secondary-color', secondaryColor)
    return () => {}
  }, [primaryColor, secondaryColor])
  const [tab, setTab] = useState('chat')
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (!tabOpen && !emailId) setTab('bot')
  }, [emailId])

  useEffect(() => {
    if (tabOpen) setTab(tabOpen)
  }, [tabOpen])

  function getContent() {
    switch (tab) {
      case 'chat':
        return <ChatsIoOneOneChat />
      case 'support':
        return (
          <SupportChatContextProvider>
            <ChatsIoSupport />
          </SupportChatContextProvider>
        )
      case 'profile':
        return profileComponent
      case 'call':
        return callComponent
      case 'bot':
        return botComponent

      case 'mail':
        return <InboxSidebar />
      default:
        break
    }
  }
  const [filter, setFilter] = useState(false)
  const [filterBy, setfilterBy] = useState('All')
  const [searchFocus, setSearchFocus] = useState(false)
  const [search, setSearch] = useState('')
  return (
    <MainContextProvider
      emailId={emailId}
      idToken={idToken}
      logo={logo}
      botLogo={botLogo}
      appCode={appCode}
    >
      <OneOneChatContextProvider APP_CODE={appCode}>
        <div
          className={`chatsIoComponent ${styles.chatsIoComponent} ${
            right && styles.right
          }`}
          ref={ref}
        >
          <div className={styles.head}>
            <BackIcon
              className={styles.btBack}
              onClick={() => {
                try {
                  onClose()
                } catch (error) {}
              }}
            />
            {logo ? (
              <img src={logo} alt='' className={`logo ${styles.logo}`} />
            ) : (
              <Logo className={`logo ${styles.logo}`} />
            )}
            <ExpandIcon className={styles.btExpand} />
          </div>
          <div className={styles.searchHead}>
            <div
              className={`${styles.all} ${filter && styles.true}`}
              onClick={() => setFilter(!filter)}
            >
              {filter ? 'Close' : filterBy}
            </div>
            <label className={styles.searchWrap}>
              <input
                type='text'
                placeholder='Search Anything'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onFocus={() => setSearchFocus(true)}
                onBlur={() => {
                  setTimeout(() => {
                    setSearchFocus(false)
                  }, 200)
                }}
              />
              <SearchIcon className={styles.searchIcn} />
            </label>
          </div>
          <div className={styles.chatsContent}>
            <ChatsIoMenu
              menuOpen={menuOpen}
              setTab={(tab) => {
                setTab(tab)
                setMenuOpen(false)
              }}
            />
            {filter && (
              <SearchFilter
                setfilterBy={(val) => {
                  setfilterBy(val)
                  setFilter(false)
                }}
              />
            )}
            {search || searchFocus ? (
              <SearchView setTab={setTab} search={search} />
            ) : (
              ''
            )}
            {getContent()}
          </div>
          <ChatsIoFooterMenu
            tab={tab}
            setTab={setTab}
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
      </OneOneChatContextProvider>
    </MainContextProvider>
  )
}

export default ChatsIoComponent
