import React from 'react';
import BotIcon from '../../images/footerIcons/botIcon.svg';
import CallIcon from '../../images/footerIcons/callIcon.svg';
import ChatIcon from '../../images/footerIcons/chatIcon.svg';
import ChatsLogo from '../../images/footerIcons/chatsLogo.svg';
import MailIcon from '../../images/footerIcons/mailIcon.svg';

import styles from './chatsIoFooterMenu.module.scss';

function ChatsIoFooterMenu({ onClick, tab, setTab }) {
  return (
    <div className={styles.footerMenu}>
      <div
        onClick={() => setTab('chat')}
        className={`${styles.iconWrap} ${
          tab === 'chat' ? styles.true : styles.false
        }`}
      >
        <img src={ChatIcon} alt="" />
        {/* <ChatIcon onClick={() => setTab('chat')} /> */}
        <span>Chat</span>
      </div>
      <div
        className={`${styles.iconWrap} ${
          tab === 'bot' ? styles.true : styles.false
        }`}
      >
        <img src={BotIcon} alt="" onClick={() => setTab('bot')} />
        {/* <BotIcon onClick={() => setTab('bot')} /> */}
        <span>Bot</span>
      </div>
      <div className={styles.center}>
        <div className={styles.wrapper}>
          <img
            src={ChatsLogo}
            alt=""
            onClick={() => {
              try {
                onClick();
              } catch (error) {}
            }}
          />
        </div>
      </div>
      <div
        className={`${styles.iconWrap} ${
          tab === 'call' ? styles.true : styles.false
        }`}
      >
        <img src={CallIcon} alt="" onClick={() => setTab('call')} />
        {/* <CallIcon onClick={() => setTab('call')} /> */}
        <span>Call</span>
      </div>
      <div
        className={`${styles.iconWrap} ${
          tab === 'mail' ? styles.true : styles.false
        }`}
      >
        <img src={MailIcon} alt="" onClick={() => setTab('mail')} />
        {/* <MailIcon onClick={() => setTab('mail')} /> */}
        <span>Mail</span>
      </div>
    </div>
  );
}

export default ChatsIoFooterMenu;
