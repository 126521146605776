import Routes from "./Routes";
import "./static/scss/master.scss";
// import "@teamforce/fullpage-search/dist/index.css";
import ChatsContextProvider from "./context/ChatsContext";
import MainContextProvider from "./context/MainContext";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <MainContextProvider>
        <ChatsContextProvider>
          <Routes />
        </ChatsContextProvider>
      </MainContextProvider>
    </QueryClientProvider>
  );
}

export default App;
