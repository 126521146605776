import React from "react";

import virtualProspectus from "../../static/images/logos/virtualProspectus.svg";

function FullPageLoader() {
  return (
    <div className="fullPageLoader">
      <img src={virtualProspectus} alt="" className="mainLogo" />
    </div>
  );
}

export default FullPageLoader;
