import React, { Fragment, useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import axios from "axios";

import supplyIcon from "../../static/images/icons/supply.svg";
import walletIcon from "../../static/images/icons/wallet.svg";
import sentimentIcon from "../../static/images/icons/sentiment.svg";
import DonutChart from "../VirtualProspectusPage/DonutChart/Index";
import { MainContext } from "../../context/MainContext";
import { FormatCurrency, FormatNumber } from "../../Utils/Functions";
import doubleArrow from "../../static/images/icons/doubleArrow.svg";
import Skeleton from "react-loading-skeleton";
import CoinCard from "./CoinCard";
import CoinSelectModal from "../CoinSelectModal/CoinSelectModal";

function StaticsCard({ card, i, next, setModalOutSpace }) {
  const [list, setList] = useState([]);
  const [usdTotal, setUsdTotal] = useState(0);
  const [segment, setSegment] = useState(null);
  const [donutData, setDonutData] = useState([]);
  const { coinSelected, tokenSelected } = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    switch (card?.key) {
      case "1":
        setLoading(true);
        axios
          .get(
            `https://api.terminals.dev/vault-set/balance?vaultSet=${tokenSelected?.path_id}&includeTotalUSD=true&email=${tokenSelected?.path_created_by}`
          )
          .then(({ data }) => {
            setList(data.wallet);
            setUsdTotal(data.totalUSD);
            let arr = [];
            data.wallet.forEach((coin, i) => {
              arr.push({
                value: parseFloat(coin.usdValue),
                name: coin?.ter_coin?.coin_name,
                coin: coin?.ter_coin?.coin_code,
                color: coin?.ter_coin?.color_code,
              });
            });
            setDonutData(arr);
          })
          .finally(() => setLoading(false));

        break;

      default:
        break;
    }
  }, [card?.key, tokenSelected?.path_created_by, tokenSelected?.path_id]);
  const [tab, setTab] = useState("Holdings");

  const [cardCoin, setCardCoin] = useState();
  useEffect(() => {
    setCardCoin(coinSelected);
  }, [coinSelected]);
  const [changeCoin, setChangeCoin] = useState(false);
  useEffect(() => {
    if (changeCoin)
      setModalOutSpace(
        <CoinSelectModal
          setCoin={setCardCoin}
          onClose={() => setChangeCoin(false)}
          coin={cardCoin}
        />
      );
    else setModalOutSpace(<Fragment />);
  }, [cardCoin, changeCoin, setModalOutSpace]);

  function getContent() {
    switch (tab) {
      case "Holdings":
        return (
          <>
            <div className="donutNcard">
              <div className="donutWrap">
                {loading ? (
                  <Skeleton
                    className="donutSvg"
                    circle
                    width={250}
                    height={250}
                  />
                ) : (
                  <DonutChart
                    className="donutSvg"
                    pieData={donutData}
                    onMouseOver={(segmentIndex) => {
                      setSegment(segmentIndex);
                    }}
                    onMouseOut={() => {
                      setSegment(null);
                    }}
                    segment={segment}
                  />
                )}
                <div
                  className={`centreDonut ${Boolean(segment || segment === 0)}`}
                >
                  {FormatNumber(
                    ((donutData && donutData[segment]?.value) / usdTotal) * 100,
                    2
                  )}
                  %
                </div>
              </div>
              <Scrollbars className="donutCards">
                <div className="cardHead">Holdings Breakdown</div>
                {loading
                  ? Array(5)
                      .fill("")
                      .map(() => (
                        <div className="detailCard">
                          <Skeleton className="sold" width={120} />
                          <Skeleton className="value" width={120} />
                          <Skeleton className="coin" circle />
                        </div>
                      ))
                  : list.map((coin, i) => (
                      <CoinCard
                        segment={segment}
                        coin={coin}
                        setSegment={setSegment}
                        i={i}
                        coinSelected={coinSelected}
                        setModalOutSpace={setModalOutSpace}
                      />
                    ))}
              </Scrollbars>
            </div>
            <div className="footer">
              <div className="total">
                <span>Total Holdings</span>
                <span>
                  {cardCoin?.symbol?.length < 3 ? cardCoin?.symbol : ""}
                  {FormatCurrency(
                    usdTotal / cardCoin?.usd_price,
                    cardCoin?.symbol
                  )}{" "}
                  {cardCoin?.symbol?.length < 3 ? "" : cardCoin?.symbol}
                </span>
              </div>
              <div className="coin">
                <img
                  src={cardCoin?.coinImage}
                  alt=""
                  onClick={() => setChangeCoin(true)}
                />
              </div>
            </div>
          </>
        );
      case "Returns":
        return (
          <>
            <Scrollbars className="returns">
              <div className="title">
                Against USDT
                <div className="date">Jan 1st 2021 - Feb 23rd 2021</div>
              </div>
              <div
                className="returnCard"
                style={{ borderLeftColor: "#00BD9A" }}
              >
                <div className="percent">+12.34%</div>
                <div className="value">+2,3512.34</div>
              </div>
              <div className="title">
                Against USD
                <div className="date">Jan 1st 2021 - Feb 23rd 2021</div>
              </div>
              <div
                className="returnCard"
                style={{ borderLeftColor: "#DC3027" }}
              >
                <div className="percent">+12.34%</div>
                <div className="value">+2,3512.34</div>
              </div>
            </Scrollbars>
          </>
        );
      case "Sentiment":
        return (
          <>
            <div className="sentiment">
              <div className="sentimentWrap">
                <div className="sentiCard">
                  <div className="value">
                    <div className="text">13.35%</div>
                    <div className="subText">Of Investors Disapprove</div>
                  </div>
                  <div className="moreData">
                    <span>More Data</span>
                    <img src={doubleArrow} alt="" />
                  </div>
                </div>
                <div className="btnSenti red">Critique</div>
              </div>
              <div className="sentimentWrap">
                <div className="sentiCard">
                  <div className="value">
                    <div className="text">76.65%</div>
                    <div className="subText">Of Investors Approve</div>
                  </div>
                  <div className="moreData">
                    <span>More Data</span>
                    <img src={doubleArrow} alt="" />
                  </div>
                </div>
                <div className="btnSenti green">Applaud</div>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  }

  return (
    <div className={`content i${i}`} onClick={() => next(i)}>
      <div className="stats">
        <div className="title">{card.title}</div>
        <div className="date">{card.date}</div>
      </div>
      <Scrollbars
        className="supplyList"
        renderTrackHorizontal={() => <div />}
        renderTrackVertical={() => <div />}
        renderThumbHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
      >
        <div
          className={`supply ${tab === "Holdings"}`}
          onClick={() => {
            setTab("Holdings");
            setSegment(null);
          }}
        >
          <img src={walletIcon} alt="" />
          <span>Holdings</span>
        </div>
        <div
          className={`supply ${tab === "Returns"}`}
          onClick={() => setTab("Returns")}
        >
          <img src={supplyIcon} alt="" />
          <span>Returns</span>
        </div>
        <div
          className={`supply ${tab === "Sentiment"}`}
          onClick={() => setTab("Sentiment")}
        >
          <img src={sentimentIcon} alt="" />
          <span>Sentiment</span>
        </div>
      </Scrollbars>
      {getContent()}
    </div>
  );
}

export default StaticsCard;
