import React, { useContext } from 'react';
import MessageList from './MessageList';
import BackIcon from '../../images/backArrow.svg';
import { OneOneChatContext } from '../../contexts/OneOneChatContext';

import styles from './chatView.module.scss';

const ChatView = () => {
  const { selectedUser, setSelectedUser } = useContext(OneOneChatContext);

  return (
    <div className={styles.chatLayoutContainer}>
      <div className={styles.chatHeader}>
        <img
          src={BackIcon}
          className={styles.chatBackButton}
          onClick={() => setSelectedUser()}
        />
        {/* <BackIcon
          className={styles.chatBackButton}
          onClick={() => setSelectedUser()}
        /> */}
        <img
          src={selectedUser?.avatar}
          alt=""
          className={styles.chatUserAvatar}
        />
        <div className={styles.chatUserContainer}>
          <div className={styles.chatUserName}>{selectedUser?.first_name}</div>
          <div className={styles.chatUserBio}>{selectedUser?.bio}</div>
        </div>
      </div>
      <MessageList />
    </div>
  );
};

export default ChatView;
