import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useContext } from "react";
import { useParams } from "react-router";
import calculator from "../../../static/images/icons/calculator.svg";
import wallet from "../../../static/images/icons/wallet.svg";
import { MainContext } from "../../../context/MainContext";
import Scrollbars from "react-custom-scrollbars";
import { FormatCurrency } from "../../../Utils/Functions";

function VaultsSidebar({
  coinSelected,
  search,
  setSearch,
  isVault,
  setIsVault,
}) {
  const { pathId } = useParams();
  useEffect(() => {
    if (coinSelected) setIsVault(false);
  }, [coinSelected, setIsVault]);

  const { tokenSelected, coinsObject } = useContext(MainContext);
  const [loading, setLoading] = useState();
  const [txnList, setTxnList] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://api.terminals.dev/vault-set/transactions?vaultSet=${pathId}&asset=${coinSelected?.ter_coin?.coin_code}&page=1&limit=20`,
        {
          headers: {
            email: tokenSelected?.path_created_by,
          },
        }
      )
      .then(({ data }) => {
        setTxnList(data?.items);
      })
      .finally(() => setLoading(false));
  }, [
    coinSelected?.ter_coin?.coin_code,
    pathId,
    tokenSelected?.path_created_by,
  ]);
  console.log(`txnList`, txnList);
  return (
    <div className="vaultsSidebar">
      <div className="header">
        <input
          type="text"
          className="serchInput"
          placeholder={isVault ? "Search Vaults" : "Search Transactions"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className={`btnBox ${isVault}`} onClick={() => setIsVault(true)}>
          <img src={wallet} alt="" />
        </div>
        <div
          className={`btnBox ${!isVault}`}
          onClick={() => {
            if (coinSelected) setIsVault(false);
          }}
        >
          <img src={calculator} alt="" />
        </div>
      </div>
      {isVault ? (
        <div className="notSelected">
          <div className="transactionTitle">Transactions</div>
          <div className="transactionSubTitle">
            Select Vault To See Transactions
          </div>
        </div>
      ) : (
        <Scrollbars className="transactionList">
          {loading
            ? ""
            : txnList
                ?.filter((txn) =>
                  txn?.tx_message?.toLowerCase().includes(search?.toLowerCase())
                )
                .map((txn) => (
                  <div className="txnItem">
                    <div className="timeAmt">
                      <span>
                        {moment(parseInt(txn.updated_at))
                          .tz("America/New_York")
                          .format("hh:mm A z")}
                      </span>
                      <span className={txn.tx_type}>
                        {txn.tx_type === "credit" ? "+" : "-"}
                        {FormatCurrency(
                          txn.amount,
                          coinSelected?.ter_coin?.coin_code
                        )}
                      </span>
                    </div>
                    <div className="dateUsd">
                      <span>
                        {moment(parseInt(txn.updated_at))
                          .tz("America/New_York")
                          .format("dddd MMMM Do YYYY")}
                      </span>
                      <span>
                        $
                        {FormatCurrency(
                          txn.amount *
                            coinsObject[coinSelected?.ter_coin?.coin_code]
                              ?.usd_price
                        )}
                      </span>
                    </div>
                  </div>
                ))}
        </Scrollbars>
      )}
    </div>
  );
}

export default VaultsSidebar;
