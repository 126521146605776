import React, { Fragment, useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment-timezone";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import axios from "axios";

import ChartTypeSelectModal from "../../ChartTypeSelectModal";
import VPCandleChart from "../VPCandleChart";
import { MainContext } from "../../../context/MainContext";
import { ChatsContext } from "../../../context/ChatsContext";
import { FormatCurrency } from "../../../Utils/Functions";
import StaticsCard from "../../StaticsCard/StaticsCard";

import supplyIcon from "../../../static/images/icons/supply.svg";
import walletIcon from "../../../static/images/icons/wallet.svg";
import cashIcon from "../../../static/images/icons/cash.svg";
import DonutChart from "../DonutChart/Index";
import CoinSelectModal from "../../CoinSelectModal/CoinSelectModal";

const listPie = [
  {
    value: 5,
    color: "#002A51",
    name: "Bitcoin",
    coin: "btc",
  },
  {
    value: 3,
    color: "#00BD9A",
    name: "Ethereum",
    coin: "eth",
  },
  {
    value: 2,
    color: "#D1EEFF",
    name: "Tether",
    coin: "usdt",
  },
];

async function getMarketCap({ queryKey }) {
  const [_, { email, vaultset }] = queryKey;
  let resp;
  if (email && vaultset) {
    resp = await axios.get(
      `https://api.terminals.dev/vault-set/vaultSetStats`,
      {
        params: {
          email,
          vaultset,
        },
      }
    );
  } else {
    throw new Error("Path & Email Not Got");
  }
  return resp.data;
}

function VirtualProspectusAnalysis() {
  const { tokenSelected, coinsObject } = useContext(MainContext);
  const { chatOn } = useContext(ChatsContext);
  const [coinForBalance, setCoinForBalance] = useState("USD");
  const [cards, setCards] = useState([]);
  const [isUsd, setIsUsd] = useState(true);
  useEffect(() => {
    setCards([
      {
        key: "1",
        title: "All Time Stats",
        date: `${moment(tokenSelected?.timestamp)
          .tz("America/New_York")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "2",
        title: "Past Seven Days",
        date: `${moment()
          .subtract(7, "d")
          .tz("America/New_York")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "3",
        title: "Past Thirty Days",
        date: `${moment()
          .subtract(30, "d")
          .tz("America/New_York")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "4",
        title: "Past 90 Days",
        date: `${moment()
          .subtract(90, "d")
          .tz("America/New_York")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
      {
        key: "5",
        title: "Past One Year",
        date: `${moment()
          .subtract(1, "year")
          .tz("America/New_York")
          .format("MMMM Do YYYY [at] h:mm A z")} - Right Now`,
      },
    ]);
    setCoinForBalance(tokenSelected?.asset);
  }, [tokenSelected]);
  useEffect(() => {
    coinsObject &&
      tokenSelected?.asset &&
      setCoinForBalance(coinsObject[tokenSelected?.asset]);
  }, [coinsObject, tokenSelected]);

  const [mobileStats, setMobileStats] = useState(false);
  function next(index) {
    if (index !== 0) {
      setCards((cards) => {
        const [first, ...rest] = cards;
        return [...rest, first];
      });
      setTimeout(() => {
        next(index - 1);
      }, 500);
    }
  }

  const [modalOutSpace, setModalOutSpace] = useState(<Fragment />);

  const { data, isLoading } = useQuery(
    [
      "marketcap",
      { email: tokenSelected?.banker_email, vaultset: tokenSelected?.path_id },
    ],
    getMarketCap,
    {
      enabled: true,
    }
  );
  const [chartType, setChartType] = useState("Market Cap");
  const [typeModal, setTypeModal] = useState(false);
  const [coinSelectModal, setCoinSelectModal] = useState(false);
  return (
    <>
      {modalOutSpace}
      <div className="vPAnalysisContent">
        <div className={`coinContent ${!mobileStats}`}>
          <div className="coinDetail">
            <div className="coinHead">
              <img src={tokenSelected?.token_profile_data?.coinImage} alt="" />
              <span className="coinName">
                {tokenSelected?.token_profile_data?.coinName}
              </span>
              <span className="coinValue">
                {FormatCurrency(
                  (tokenSelected?.token_price *
                    (coinsObject &&
                      coinsObject[tokenSelected?.asset]?.usd_price)) /
                    coinForBalance?.usd_price,
                  coinForBalance?.coinSymbol
                )}{" "}
                <div
                  className="coinBtn"
                  onClick={() => setCoinSelectModal(true)}
                >
                  <img src={coinForBalance?.coinImage} alt="" />
                  {coinForBalance?.coinSymbol}
                </div>
              </span>
            </div>
            <p className="desc">
              {tokenSelected?.token_profile_data?.coinDescription}
            </p>
            <div className="statusBt" onClick={() => setMobileStats(true)}>
              <div className="textMain">Stastics</div>
              <div className="subText">Compare Timestamped Stats</div>
              <div className="arrow">❯</div>
            </div>
          </div>
          {isLoading ? (
            <div className="vpCandleHead">
              <div className="coinView">
                <div className="rate">
                  <Skeleton width={120} />
                </div>
                <div className="coin">
                  <Skeleton width={40} height={40} />
                </div>
              </div>
              <div className="marketView">
                <Skeleton width={200} />
              </div>
              <div className="coinView">
                <div className="rate">
                  <Skeleton width={120} />
                </div>
                <div className="coin">
                  <Skeleton width={40} height={40} />
                </div>
              </div>
            </div>
          ) : (
            <div className="vpCandleHead">
              <div
                className={`coinView ${isUsd}`}
                onClick={() => setIsUsd(true)}
              >
                <div className="rate">
                  $
                  {FormatCurrency(
                    data?.holdingsAgainstUSD?.date2?.total,
                    "USD"
                  )}
                </div>
                <div className="coin">
                  <img src={coinsObject?.USD?.coinImage} alt="" />
                </div>
              </div>
              <div className="marketView" onClick={() => setTypeModal(true)}>
                {chartType}
                <div className="against">
                  Against {isUsd ? "USD" : data?.displayCurrency}
                </div>
              </div>
              <div
                className={`coinView ${!isUsd}`}
                onClick={() => setIsUsd(false)}
              >
                <div className="rate">
                  $
                  {FormatCurrency(
                    data?.holdingsAgainstDisplayCurrency?.date2?.total,
                    data?.displayCurrency
                  )}
                </div>
                <div className="coin">
                  <img
                    src={
                      coinsObject &&
                      coinsObject[data?.displayCurrency]?.coinImage
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
          <VPCandleChart />
        </div>
        <div className={`mobileStats  ${mobileStats}`}>
          <div className="header">
            Statistics
            <div className="closeBt" onClick={() => setMobileStats(false)}>
              ✕
            </div>
          </div>
          <Scrollbars
            className="supplyList"
            renderTrackHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="supply">
              <img src={walletIcon} alt="" />
              Holdings
            </div>
            <div className="supply">
              <img src={supplyIcon} alt="" />
              Returns
            </div>
            <div className="supply">
              <img src={cashIcon} alt="" />
              Liquidity
            </div>
          </Scrollbars>
          <div className="coinVal">
            <span>1,035,256.35</span>
            <div className="coin">
              <img
                src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png"
                alt=""
              />
              USD
            </div>
          </div>
          <div className="donutWrap">
            <DonutChart
              className="donutSvg"
              pieData={listPie}
              onMouseOver={(segmentIndex) => {}}
              onMouseOut={() => {}}
              segment={null}
            />
          </div>
          <div className="donutCards">
            <div
              className="detailCard"
              style={{ borderLeftColor: listPie[0].color }}
            >
              <div className="sold">Sold</div>
              <div className="value">$1,245.45</div>
              <img
                src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png"
                alt=""
                className="coin"
              />
            </div>
            <div
              className="detailCard"
              style={{ borderLeftColor: listPie[1].color }}
            >
              <div className="sold">Sold</div>
              <div className="value">$1,245.45</div>
              <img
                src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png"
                alt=""
                className="coin"
              />
            </div>
            <div
              className="detailCard"
              style={{ borderLeftColor: listPie[2].color }}
            >
              <div className="sold">Sold</div>
              <div className="value">$1,245.45</div>
              <img
                src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png"
                alt=""
                className="coin"
              />
            </div>
          </div>
        </div>
        {!chatOn && (
          <div className="statsContent">
            <div className="selectedStats">
              <div className="title">{cards[0]?.title}</div>
              <div className="date">{cards[0]?.date}</div>
            </div>
            <div className="statsContainer">
              {cards.map((card, i) => (
                <StaticsCard
                  key={card.key}
                  card={card}
                  i={i}
                  next={next}
                  setModalOutSpace={setModalOutSpace}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      {typeModal && (
        <ChartTypeSelectModal
          type={chartType}
          setType={setChartType}
          onClose={() => setTypeModal(false)}
        />
      )}
      {coinSelectModal && (
        <CoinSelectModal
          coin={coinForBalance}
          setCoin={setCoinForBalance}
          onClose={() => setCoinSelectModal(false)}
        />
      )}
    </>
  );
}

export default VirtualProspectusAnalysis;
