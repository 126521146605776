import React, { Fragment, useContext } from 'react'
import ChatView from './ChatView'
import { timeAgoFormatter } from '../../utils/FunctionTools'
import { ReactComponent as SearchIcon } from '../../images/search.svg'

import styles from './oneOneChat.module.scss'
import { OneOneChatContext } from '../../contexts/OneOneChatContext'
import LoadingAnim from '../LoadingAnim/LoadingAnim'

const ChatsIoOneOneChat = () => {
  const {
    isSocketConnected,
    allUsers,
    setSelectedUser,
    selectedUser,
    recentList,
    setCurrentTreadId
  } = useContext(OneOneChatContext)

  if (selectedUser) {
    return <ChatView />
  }

  return (
    <div className={styles.oneOneChatWrapper}>
      {isSocketConnected ? (
        <Fragment>
          <div className={styles.searchContainer}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type='text'
              className={styles.searchInput}
              placeholder='Search Contacts'
            />
          </div>
          <div className={styles.header}>Recents</div>
          {recentList ? (
            <div className={styles.recentChatsContainer}>
              {recentList.map((item, index) => (
                <div
                  key={index}
                  className={styles.recentChatItem}
                  onClick={() => {
                    setCurrentTreadId(item.thread_id)
                    setSelectedUser(item)
                  }}
                >
                  <img
                    src={item.avatar}
                    alt=''
                    className={styles.avatarImage}
                  />
                  <div className={styles.name}>{item.first_name?.trim()}</div>
                  <div className={styles.time}>
                    {timeAgoFormatter(item.msg_timestamp)}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <LoadingAnim />
          )}
          <div className={styles.header}>Friends</div>
          {allUsers ? (
            <div className={styles.friendsListContainer}>
              {allUsers.map((item, index) => (
                <div
                  key={index}
                  className={styles.friendItem}
                  onClick={() => setSelectedUser(item)}
                >
                  <img
                    src={item.avatar}
                    alt=''
                    className={styles.avatarImage}
                  />
                  <div className={styles.wrapper}>
                    <div className={styles.name}>{`${item.first_name?.trim()} ${
                      (item.last_name || '')[0]
                    }`}</div>
                    <div className={styles.time}>{item.bio}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <LoadingAnim />
          )}
        </Fragment>
      ) : (
        <div className={styles.loadingWraper}>
          <LoadingAnim />
        </div>
      )}
    </div>
  )
}

export default ChatsIoOneOneChat
