import React, { useRef, useEffect, useContext, useState } from "react";
import { createChart, PriceScaleMode } from "lightweight-charts";
import axios from "axios";
import { useQuery } from "react-query";
import moment from "moment";
import { MainContext } from "../../../context/MainContext";
import marketCharts from "../../../static/images/logos/marketCharts.svg";
import ChartFrequencySelectModal from "../../ChartFrequencySelectModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import ChartSettingsModal from "../../ChartSettingsModal";

async function getChartData({ queryKey }) {
  const [_, { email, vaultset }] = queryKey;
  if (email && vaultset) {
    const resp = await axios.get(
      `https://api.terminals.dev/vault-set/vaultSetStats/historical?email=${email}&vaultSet=${vaultset}`
    );
    return resp.data;
  }
  return;
}

function GetSortOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return -1;
    } else if (a[prop] > b[prop]) {
      return 1;
    }
    return 0;
  };
}

function VPCandleChart({ isUsd }) {
  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();
  const candleSeries = useRef();
  const lineSeries = useRef();

  const { tokenSelected } = useContext(MainContext);
  const { data, isLoading } = useQuery(
    [
      "chartData",
      { email: tokenSelected?.banker_email, vaultset: tokenSelected?.path_id },
    ],
    getChartData
  );

  const [chartType, setChartType] = useState("Candle Stick");

  // Create Chart & Candle Series & Line Chart
  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: "#fff",
        textColor: "#464b4e",
        fontFamily: "Montserrat",
      },
      grid: {
        vertLines: {
          visible: false,
          color: "#334158",
        },
        horzLines: {
          visible: false,
          color: "#334158",
        },
      },
      priceScale: {
        position: "left",
        borderVisible: true,
        borderColor: "#485c7b",
        visible: true,
        alignLabels: true,
        drawTicks: true,
        mode: PriceScaleMode.Normal,
      },
      timeScale: {
        borderVisible: true,
        borderColor: "#485c7b",
        visible: true,
        timeVisible: false,
      },
      crosshair: {
        vertLine: {
          visible: true,
          labelVisible: true,
        },
        horzLine: {
          visible: true,
          labelVisible: true,
        },
        mode: 1,
      },
    });

    candleSeries.current = chart.current.addCandlestickSeries({
      upColor: "#182542",
      downColor: "#FF2D55",
      borderDownColor: "#FF2D55",
      borderUpColor: "#182542",
      wickDownColor: "#FF2D55",
      wickUpColor: "#182542",
      priceLineVisible: false,
      lastValueVisible: false,
    });

    lineSeries.current = chart.current.addLineSeries({
      color: "#182542",
      lineStyle: 0,
      lineWidth: 1,
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 3,
      crosshairMarkerBackgroundColor: "#2296f3",
      lineType: 1,
      priceLineVisible: false,
      lastValueVisible: false,
    });
  }, []);

  // Sort & Set Data For Chart
  const [dataForChart, setDataForChart] = useState([]);
  useEffect(() => {
    if (!isLoading && data?.items) {
      switch (chartType) {
        case "Line Chart":
          {
            let dataForLineChart = [];
            data.items.sort(GetSortOrder("date1")).forEach((item, i) => {
              const date = moment(item.date1).format("YYYY-MM-DD");
              dataForLineChart.push({
                time: date,
                value: isUsd
                  ? item?.holdingsAgainstUSD?.date2Total
                  : item?.holdingsAgainstDisplayCurrency?.date2Total,
              });
            });
            lineSeries.current?.setData(dataForLineChart);
            candleSeries.current?.setData([]);
            setDataForChart(dataForLineChart);
          }
          break;

        default:
          {
            let dataForCandleChart = [];
            data.items.sort(GetSortOrder("date1")).forEach((item, i) => {
              const date = moment(item.date1).format("YYYY-MM-DD");
              dataForCandleChart.push({
                time: date,
                open: isUsd
                  ? item?.holdingsAgainstUSD?.date1Total
                  : item?.holdingsAgainstDisplayCurrency?.date1Total,
                close: isUsd
                  ? item?.holdingsAgainstUSD?.date2Total
                  : item?.holdingsAgainstDisplayCurrency?.date2Total,
                high: isUsd
                  ? Math.max(
                      item?.holdingsAgainstUSD?.highMarketCap || 0,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    )
                  : Math.max(
                      item?.holdingsAgainstDisplayCurrency?.highMarketCap || 0,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    ),
                low: isUsd
                  ? Math.min(
                      item?.holdingsAgainstUSD?.lowMarketCap ||
                        item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    )
                  : Math.min(
                      item?.holdingsAgainstDisplayCurrency?.lowMarketCap ||
                        item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date1Total,
                      item?.holdingsAgainstUSD?.date2Total
                    ),
              });
            });
            candleSeries.current?.setData(dataForCandleChart);
            lineSeries.current?.setData([]);
            setDataForChart(dataForCandleChart);
            console.log(`dataForCandleChart`, dataForCandleChart);
          }
          break;
      }
    }
  }, [chartType, data, isLoading, isUsd]);

  // Resize Observer
  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      chart.current.applyOptions({ width, height });
      setTimeout(() => {
        chart.current.timeScale().fitContent();
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);

  useEffect(() => {
    if (dataForChart.length >= 5) {
      chart?.current?.timeScale().setVisibleLogicalRange({
        from: dataForChart.length - 13,
        to: dataForChart.length - 1,
      });
    } else {
      chart?.current?.timeScale().fitContent();
    }
  }, [dataForChart]);

  const [modalFrequency, setModalFrequency] = useState(false);
  const [frequency, setFrequency] = useState("Days");
  const [settingsModal, setSettingsModal] = useState(false);
  return (
    <>
      <div className="vpCandleChart">
        <div ref={chartContainerRef} className="chart-container" />
        <div className="days" onClick={() => setModalFrequency(true)}>
          Days
        </div>
        <img
          src={marketCharts}
          alt=""
          className="logo"
          onClick={() => {
            window.open("https://marketcharts.co/", "_blank").focus();
          }}
        />
        <div className="settingsBtn" onClick={() => setSettingsModal(true)}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
      {modalFrequency && (
        <ChartFrequencySelectModal
          frequency={frequency}
          setFrequency={setFrequency}
          onClose={() => setModalFrequency(false)}
        />
      )}
      {settingsModal && (
        <ChartSettingsModal
          chartType={chartType}
          setChartType={setChartType}
          onClose={() => setSettingsModal(false)}
        />
      )}
    </>
  );
}

export default VPCandleChart;
