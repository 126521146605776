import React from 'react'
import ReactSafeHtml from 'react-safe-html'
import styles from './inboxSidebar.module.scss'

function OpenMailView({ onClose, mailDetail }) {
  return (
    <div className={styles.openMailView}>
      <div className={styles.head}>
        <svg
          className={styles.icon}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 448 512'
          onClick={() => {
            try {
              onClose()
            } catch (error) {}
          }}
        >
          <path
            fill='currentColor'
            d='M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z'
          />
        </svg>
        <svg
          className={`${styles.icon} ${styles.del}`}
          onClick={() => {
            try {
              onClose()
            } catch (error) {}
          }}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 448 512'
        >
          <path
            fill='currentColor'
            d='M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z'
          />
        </svg>
        <svg
          className={styles.icon}
          onClick={() => {
            try {
              onClose()
            } catch (error) {}
          }}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'
        >
          <path
            fill='currentColor'
            d='M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z'
          />
        </svg>
        <svg
          className={styles.icon}
          onClick={() => {
            try {
              onClose()
            } catch (error) {}
          }}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 192 512'
        >
          <path
            fill='currentColor'
            d='M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z'
          />
        </svg>
      </div>
      <div className={styles.content}>
        <div className={styles.subject}>{mailDetail.subject}</div>
        <div className={styles.body}>
          <ReactSafeHtml html={mailDetail.body} />
        </div>
      </div>
    </div>
  )
}

export default OpenMailView
