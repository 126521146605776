import React, { Fragment, useContext, useEffect } from 'react'
import moment from 'moment-timezone'
// import FilePreviewOnChat from '../FilePreviewOnChat';

import ChatInputArea from './ChatInputArea'
import { OneOneChatContext } from '../../contexts/OneOneChatContext'
import styles from './messageList.module.scss'
import LoadingAnim from '../LoadingAnim/LoadingAnim'
import { MainContext } from '../../contexts/MainContext'

const MessageList = () => {
  const {
    messageArray,
    isUserTyping,
    userObject,
    selectedUser,
    sendMessage,
    notifyTyping
  } = useContext(OneOneChatContext)

  useEffect(() => {
    if (messageArray?.length > 1) {
      const objDiv = document.getElementById('chat-area')
      // console.log('top: ', objDiv.scrollTop, 'height: ', objDiv.scrollHeight);
      try {
        objDiv.scrollTop = objDiv.scrollHeight
      } catch (error) {}

      // console.log(objDiv);
    }
  }, [messageArray, isUserTyping])

  const handleSubmitMessage = (inputText, setInputText) => {
    const message = inputText.trim()

    if (!message) {
      // tostShowOn('Please Input A Message')
    }

    sendMessage(message)
    setInputText('')
  }

  return (
    <div className={styles.chatAreaWrapper}>
      {messageArray ? (
        <Fragment>
          {messageArray?.length > 0 ? (
            <div
              id='chat-area'
              className={styles.chats}
              style={{ height: 0, overflowY: 'auto' }}
            >
              {messageArray?.map((item) => {
                return item.type !== 'group' ? (
                  <Message
                    selectedUser={selectedUser}
                    key={item.timestamp}
                    messageObj={item}
                    isSent={userObject?.username === item.sender?.username}
                  />
                ) : (
                  <InChatInfo key={item.timestamp} message={item.message} />
                )
              })}
            </div>
          ) : (
            <div className={styles.noChats}>
              <div className={styles.textCenter}>
                Start Your Conversation with {selectedUser?.first_name}
              </div>
            </div>
          )}
          <br />
          {isUserTyping && (
            <div className={styles.typingIndicator}>
              {`${selectedUser?.first_name?.trim()} is Typing`}
              &nbsp;&nbsp;&nbsp;
              <div className={styles.ticontainer}>
                <div className='tiblock'>
                  <div className={styles.tidot} />
                  <div className={styles.tidot} />
                  <div className={styles.tidot} />
                </div>
              </div>
            </div>
          )}
          <ChatInputArea
            onTypingHandler={notifyTyping}
            onSubmit={handleSubmitMessage}
            isFileLoading={false}
            initiateSendImage={() => null}
            disabledFilePicker
          />
        </Fragment>
      ) : (
        <div className={styles.loadingWraper}>
          <LoadingAnim />
        </div>
      )}
    </div>
  )
}

const InChatInfo = ({ message }) => {
  return (
    <div className={styles.inChatInfo}>
      <span>{message}</span>
    </div>
  )
}

const Message = ({ messageObj, isSent, selectedUser }) => {
  const { profileImg } = useContext(MainContext)
  return (
    <Fragment>
      {isSent ? (
        <div className={styles.answer}>
          <div className={styles.questionWrap}>
            {/* {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )} */}
            <p className={styles.questionText}>{messageObj.message}</p>
          </div>
          <div className={styles.bot}>
            <img
              className={styles.imgLogo}
              src={
                profileImg ||
                `https://avatars.dicebear.com/4.5/api/avataaars/${messageObj?.sender?.first_name}.svg`
              }
              alt={messageObj?.sender?.first_name}
            />
            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
        </div>
      ) : (
        <div className={styles.question}>
          <div className={styles.bot}>
            <div className={styles.appLogoContainer}>
              <img
                className={styles.appLogo}
                src={selectedUser?.avatar}
                alt={messageObj.sender}
              />
            </div>

            <p>{moment(messageObj.timestamp).format('hh:mm A')}</p>
          </div>
          <div className={styles.questionWrap}>
            {/* {messageObj.location && (
              <FilePreviewOnChat
                link={messageObj.location}
                type={messageObj.type}
              />
            )} */}
            <p className={styles.questionText}>{messageObj.message}</p>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default MessageList
