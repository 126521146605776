/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import documentIcon from '../../images/chatsLogo.svg'

function FilePreviewModal({
  chatInput,
  setChatInput,
  fileToUpload,
  onClose,
  onSubmit,
  isFileLoading
}) {
  const getPreview = () => {
    switch (fileToUpload.type.split('/')[0]) {
      case 'image':
        return (
          <img
            className='previewItem'
            src={URL.createObjectURL(fileToUpload)}
            alt=''
          />
        )
      case 'video':
        return (
          <video
            autoPlay={false}
            controls
            className='previewItem'
            src={URL.createObjectURL(fileToUpload)}
            alt=''
          />
        )
      default:
        return <img className='previewItem file' src={documentIcon} alt='' />
    }
  }

  return (
    <div className='fileModalWrapper'>
      <div className='overlay' onClick={onClose} />
      <div className='selectFileModal'>
        <div className='head'>Upload File</div>
        <div className='preview'>
          <svg viewBox='0 0 16 9' />
          {getPreview()}
        </div>
        <input
          type='text'
          className='textInput'
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          placeholder='Message'
        />
        <div className='btnSend' onClick={() => !isFileLoading && onSubmit()}>
          {isFileLoading ? 'Uploading' : 'Send'}
        </div>
      </div>
    </div>
  )
}

export default FilePreviewModal
