import React from 'react'
import ChatArea from './ChatView/ChatArea'
import ChatInputs from './ChatView/ChatInputs'
import styles from './supportChat.module.scss'
function ChatsIoSupport() {
  return (
    <div className={styles.supportChat}>
      <ChatArea />
      <ChatInputs />
    </div>
  )
}

export default ChatsIoSupport
