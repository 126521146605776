import React, { Fragment, useContext, useState } from 'react'
import Axios from 'axios'
import styles from './loginComponent.module.scss'
import LoadingAnim from '../LoadingAnim/LoadingAnim'
import { MainContext } from '../../contexts/MainContext'

function LoginComponent({ onClose }) {
  const { login } = useContext(MainContext)
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const loginvalidate = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setLoading(true)
      Axios.post('https://gxauth.apimachine.com/gx/user/login', {
        email,
        password
      })
        .then((response) => {
          const { data } = response
          if (data.status) {
            login(email, data.idToken)
            onClose()
          } else {
            console.log(data.message)
          }
        })
        .catch((error) => {
          console.log(error.message ? error.message : 'Some Thing Went Wrong!')
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      console.log('Enter Valid EmailId')
    }
  }
  function getContent() {
    switch (step) {
      case 1:
        return (
          <Fragment>
            <div className={styles.title}>Login</div>
            <div className={styles.subtitle}>Step 2: Enter Your Password</div>
            <input
              type='password'
              className={styles.input}
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className={styles.next} onClick={() => loginvalidate()}>
              Next
            </div>
            <div className={styles.click} onClick={() => setStep(0)}>
              Click Here To <b>Go Back</b>
            </div>
          </Fragment>
        )
      default:
        return (
          <Fragment>
            <div className={styles.title}>Login</div>
            <div className={styles.subtitle}>Step 1: Enter Your Email</div>
            <input
              type='text'
              className={styles.input}
              placeholder='E-mail'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={styles.next} onClick={() => setStep(1)}>
              Next
            </div>
            <div className={styles.click}>
              Click Here To <b>Register</b>
            </div>
          </Fragment>
        )
    }
  }
  return (
    <div className={styles.loginComponent}>
      {getContent()}
      {loading && (
        <div className={styles.loadingWraper}>
          <LoadingAnim />
        </div>
      )}
    </div>
  )
}

export default LoginComponent
