import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pages/HomePage";
import VPPage from "./pages/VPPage";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/:pathId" component={VPPage} />
        <Route exact path="/:pathId/:tabSelected" component={VPPage} />
        <Route exact path="/prospectus/:invType/:pathId" component={VPPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
