import React, { Fragment, useState } from 'react'
import { ReactComponent as CashIn } from '../../images/menuIcons/cashIn.svg'
import { ReactComponent as Exit } from '../../images/menuIcons/exit.svg'
import { ReactComponent as Qr } from '../../images/menuIcons/qr.svg'
import { ReactComponent as Wallet } from '../../images/menuIcons/wallet.svg'
import { ReactComponent as ChatsLogo } from '../../images/footerIcons/chatsLogo.svg'

import styles from './chatsIoMenu.module.scss'
import LoginComponent from '../LoginComponent/LoginComponent'

function ChatsIoMenu({ menuOpen, setTab }) {
  const [step, setStep] = useState('')
  function getContent() {
    switch (step) {
      case 'loginUser':
        return (
          <LoginComponent
            onClose={() => {
              setStep('')
              setTab('chat')
            }}
          />
        )
      case 'login':
        return (
          <Fragment>
            <div className={styles.title}>Chats Power Menu</div>
            <div className={styles.subtitle}>
              Please Select One Of The Following Options
            </div>
            <div className={styles.scrlView}>
              <div
                className={styles.menuItm}
                onClick={() => setStep('loginUser')}
              >
                <ChatsLogo />
                <span>I Have A Chats.io Account</span>
              </div>
              <div className={styles.menuItm} onClick={() => {}}>
                <Qr />
                <span>I Don’t Have A Chats.io Account</span>
              </div>
            </div>
          </Fragment>
        )
      default:
        return (
          <Fragment>
            <div className={styles.title}>Chats Power Menu</div>
            <div className={styles.subtitle}>
              Please Select One Of The Following Options
            </div>
            <div className={styles.scrlView}>
              <div className={styles.menuItm} onClick={() => setStep('login')}>
                <ChatsLogo />
                <span>Login</span>
              </div>
              <div className={styles.menuItm} onClick={() => setTab('support')}>
                <Qr />
                <span>Speak To Support</span>
              </div>
              <div className={`${styles.menuItm} ${styles.disable}`}>
                <Wallet />
                <span>Manage Contacts</span>
              </div>
              <div className={`${styles.menuItm} ${styles.disable}`}>
                <CashIn />
                <span>Schedule A Meeting</span>
              </div>
              <div className={`${styles.menuItm} ${styles.disable}`}>
                <Exit />
                <span>Chats.io MarketPlace</span>
              </div>
              <div className={styles.menuItm} onClick={() => setTab('profile')}>
                <Exit />
                <span>Edit Profile</span>
              </div>
            </div>
          </Fragment>
        )
    }
  }
  return (
    <div
      className={`${styles.chatsIoMenu} ${
        menuOpen ? styles.true : styles.false
      }`}
    >
      {getContent()}
    </div>
  )
}

export default ChatsIoMenu
