import React, { useEffect, useRef } from 'react'
import styles from './searchbar.module.scss'

function Searchbar({
  search,
  setSearch,
  placeholder,
  iconOne,
  iconTwo,
  width
}) {
  const ref = useRef()
  useEffect(() => {
    ref?.current && ref.current.focus()
  }, [])
  return (
    <div className={styles.search} style={{ width }}>
      <input
        ref={ref}
        type='text'
        className={styles.textInput}
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {iconOne && (
        <div
          className={`${styles.icon} ${iconOne.active && styles.true}`}
          onClick={() => {
            try {
              iconOne.onClick()
            } catch (error) {}
          }}
        >
          <img src={iconOne.icon} alt='' />
        </div>
      )}
      {iconTwo && (
        <div
          className={`${styles.icon} ${iconTwo.active && styles.true}`}
          onClick={() => {
            try {
              iconTwo.onClick()
            } catch (error) {}
          }}
        >
          <img src={iconTwo.icon} alt='' />
        </div>
      )}
    </div>
  )
}

export default Searchbar
