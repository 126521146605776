import React, { useState } from "react";
import { ReactComponent as ChatsIcon } from "../../static/images/icons/chats.svg";
import styles from "./_mainNavbar.module.scss";
import hamBurgers from "./_hamburgers.module.scss";
import SettingsSelectModal from "../../components/SettingsSelectModal/SettingsSelectModal";

function MainNavbar({
  className,
  logo,
  onLogoClick,
  chatOn,
  setChatOn,
  btIcon,
  onBtClick,
  btLabel,
}) {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <nav className={`${styles.navCompact} ${className}`}>
      <div
        onClick={() => setNavOpen(!navOpen)}
        className={`${hamBurgers.hamburger} ${hamBurgers.hamburgerSqueeze} ${
          navOpen ? hamBurgers.isActive : ""
        } `}
      >
        <span className={hamBurgers.hamburgerBox}>
          <span className={hamBurgers.hamburgerInner} />
        </span>
      </div>
      {navOpen && <SettingsSelectModal onClose={() => setNavOpen(false)} />}
      <div
        className={styles.navHome}
        onClick={() => {
          try {
            onBtClick();
          } catch (error) {}
        }}
      >
        <div className={styles.logo}>
          <img src={btIcon} alt="" />
          <div className={`${styles.label} ${styles.left}`}>{btLabel}</div>
        </div>
      </div>
      <div className={styles.area}>
        <img
          src={logo}
          alt=""
          className={styles.society}
          onClick={() => {
            try {
              onLogoClick();
            } catch (error) {}
          }}
        />
      </div>
      <div className={styles.navHome}>
        <div className={styles.logo} onClick={() => setChatOn(!chatOn)}>
          <ChatsIcon />
          <div className={`${styles.label} ${styles.right}`}>Chat With Us</div>
        </div>
      </div>
    </nav>
  );
}

export default MainNavbar;
