import React, { createContext, useState } from "react";

export const ChatsContext = createContext();
function ChatsContextProvider({ children }) {
  const [chatOn, setChatOn] = useState(false);
  return (
    <ChatsContext.Provider value={{ chatOn, setChatOn }}>
      {children}
    </ChatsContext.Provider>
  );
}

export default ChatsContextProvider;
