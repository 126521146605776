import axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import qr from "../../static/images/icons/qr.svg";
import settings from "../../static/images/icons/settings.svg";
import analytic from "../../static/images/icons/analytic.svg";
import useWindowDimensions from "../../Utils/WindowSize";
import { useHistory } from "react-router";

function CoinListHome({
  investmentType,
  investmentSubType,
  tokenSelected,
  setTokenSelected,
  pathType,
}) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [tokenList, setTokenList] = useState([]);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [count, setCount] = useState(0);
  useEffect(() => {
    setTokenLoading(true);
    setTokenList([]);
    axios
      .get(
        `https://comms.globalxchange.io/coin/investment/path/get?${
          investmentSubType
            ? "investmentSubType=" + investmentSubType?.code
            : ""
        }${investmentType ? "&investmentType=" + investmentType?.code : ""}${
          pathType ? "&pathType=" + pathType : ""
        }`
      )
      .then(({ data }) => {
        if (data.status) {
          setTokenList(data.paths);
          setCount(data.count);
        }
      })
      .finally(() => setTokenLoading(false));
  }, [investmentSubType, investmentType, pathType]);

  const { width } = useWindowDimensions();

  function getPlaceholder() {
    switch (true) {
      case Boolean():
        return `Find A ${investmentSubType.name} Offering....|`;
      case Boolean(investmentType):
        return `Find A ${investmentType.name} Offering....|`;

      default:
        return `Find An Offering....|`;
    }
  }

  return (
    <div className="listNhead">
      <label className="head">
        <input
          type="text"
          placeholder={getPlaceholder()}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="btIcon disble">
          <img src={qr} alt="" />
        </div>
        <div className="btIcon">
          <img src={settings} alt="" />
        </div>
      </label>
      <Scrollbars
        className="scrollList"
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
        renderTrackVertical={() => <div />}
      >
        {tokenLoading ? (
          Array(9)
            .fill("")
            .map((_, i) => (
              <div className="item">
                <Skeleton className="icon" />
                <Skeleton width={200} />
              </div>
            ))
        ) : tokenList?.filter(
            (token) =>
              token?.token_profile_data?.coinSymbol
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              token?.token_profile_data?.coinName
                ?.toLowerCase()
                .includes(search.toLowerCase())
          ).length ? (
          tokenList
            ?.filter(
              (token) =>
                token?.token_profile_data?.coinSymbol
                  ?.toLowerCase()
                  .includes(search.toLowerCase()) ||
                token?.token_profile_data?.coinName
                  ?.toLowerCase()
                  .includes(search.toLowerCase())
            )
            .map((token) => (
              <div
                className={`item ${
                  token?.token_profile_data?.coinSymbol ===
                  tokenSelected?.token_profile_data?.coinSymbol
                }`}
                key={token._id}
                onClick={() => {
                  setTokenSelected(token);
                  if (width < 768) {
                    history.push(`/${token.path_id}`);
                  }
                }}
              >
                <img
                  src={token?.token_profile_data?.coinImage}
                  alt=""
                  className="icon"
                />
                <span>{token?.token_profile_data?.coinName}</span>
              </div>
            ))
        ) : (
          <div className="notFound">
            <div className="text">
              Sorry We Couldn’t Find Any Offerings That Match Your Search
            </div>
          </div>
        )}
      </Scrollbars>
      <div className="listFooter">
        <div className="offering">{count} Offerings</div>
        <div className="investors">{count} Investors</div>
        <div className="btn">
          <img src={analytic} alt="" />
        </div>
      </div>
    </div>
  );
}

export default CoinListHome;
