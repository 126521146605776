import Axios from "axios";
import MailSlurp from "mailslurp-client";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import OpenMailView from "./OpenMailView";
import styles from "./inboxSidebar.module.scss";
import { MainContext } from "../../contexts/MainContext";

const API_KEY =
  "86cee2f39d56b3b5a6b2e4c827cc1382d1be6bad16a9d35cd0e659ef9272d02c";

function InboxSidebar() {
  const { username, name, profileImg } = useContext(MainContext);
  const [mailList, setMailList] = useState([]);
  const mailslurp = new MailSlurp({
    apiKey: API_KEY,
  });
  const inboxController = mailslurp.inboxController;
  const updateMailList = async (mailBoxId) => {
    const mails = await inboxController.getEmails(
      mailBoxId,
      10,
      0,
      undefined,
      undefined,
      undefined,
      "DESC"
    );
    setMailList(mails);
  };

  useEffect(() => {
    if (username) {
      Axios.get("https://api.mailslurp.com/inboxes/paginated", {
        params: { search: username },
        headers: {
          "x-api-key": API_KEY,
        },
      }).then(async ({ data }) => {
        let mailBoxId = "";
        const boxMailId = `${username}@blockcheck.io`;
        data.content.forEach((inbox) => {
          if (inbox.emailAddress === boxMailId) {
            mailBoxId = inbox.id;
          }
        });
        if (!mailBoxId) {
          const inbox = await inboxController.createInbox(
            "",
            boxMailId,
            new Date(0),
            false,
            name,
            ["assets.io"]
          );
          mailBoxId = inbox.id;
        }
        updateMailList(mailBoxId);
      });
    }
  }, [username]);

  const [openMailId, setopenMailId] = useState("");
  const [mailDetail, setMailDetail] = useState("");
  const openMail = async (openMailId) => {
    const mailDetail = await mailslurp.getEmail(openMailId);
    setMailDetail(mailDetail);
    console.log("mailDetail", mailDetail);
  };
  useEffect(() => {
    if (openMailId) openMail(openMailId);
  }, [openMailId]);

  return (
    <div className={styles.inboxSidebar}>
      <div className={styles.profile}>
        <img
          className={styles.profilePic}
          src={
            profileImg || `https://ui-avatars.com/api/?name=${name || username}`
          }
          alt=""
        />
        <div className={styles.nameEmail}>
          <div className={styles.name}>{name || username}</div>
          <div className={styles.email}>{username}@blockcheck.io</div>
        </div>
      </div>
      {openMailId ? (
        <OpenMailView
          mailDetail={mailDetail}
          onClose={() => setopenMailId("")}
        />
      ) : (
        <div className={styles.mailList}>
          {mailList.map((mail) => (
            <div
              key={mail.id}
              className={`${styles.mailItem} ${
                mail.read ? styles.true : styles.false
              }`}
              onClick={() => setopenMailId(mail.id)}
            >
              <img
                src={`https://ui-avatars.com/api/?name=${mail.from}`}
                alt=""
                className={styles.profileImg}
              />
              <div className={styles.texts}>
                <div className={styles.from}>{mail.from}</div>
                <div className={styles.subject}>{mail.subject}</div>
                <div className={styles.time}>
                  {moment(mail.created).format(
                    "MMMM Do YYYY [at] h:mm:ss A zz"
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default InboxSidebar;
