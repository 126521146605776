import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MainContext } from "../../context/MainContext";
import virtualProspectus from "../../static/images/logos/virtualProspectus.svg";
import styles from "./modalCoinSelect.module.scss";

function CoinSelectModal({ coin, setCoin, onClose }) {
  const { coinList, coinLoading, coinSelected } = useContext(MainContext);
  const selectCoin = coin || coinSelected;
  const [searchStr, setSearchStr] = useState("");
  const [type, setType] = useState("fiat");
  return (
    <>
      <div className={styles.modalCountrySelect}>
        <div className={styles.overlayClose} onClick={() => onClose()} />
        <div className={styles.modalContent}>
          <div className={styles.head}>
            <img src={virtualProspectus} alt="" />
          </div>
          <div className={styles.content}>
            <input
              value={searchStr}
              type="text"
              placeholder={`Search ${type} Currencies...|`}
              className={styles.searchCountry}
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <Scrollbars
              className={styles.countryListScroll}
              renderThumbHorizontal={() => <div />}
              renderThumbVertical={() => <div />}
              renderView={(props) => (
                <div {...props} className={styles.countryList} />
              )}
            >
              {coinLoading
                ? Array(6)
                    .fill("")
                    .map((coin) => (
                      <div className={styles.listCountry}>
                        <Skeleton className={styles.name} width={200} />
                        <Skeleton circle className={styles.flag} />
                      </div>
                    ))
                : coinList
                    .filter((coin) => coin.type === type)
                    .filter(
                      (coin) =>
                        coin.coinSymbol
                          .toLowerCase()
                          .includes(searchStr.toLowerCase()) ||
                        coin.coinName
                          .toLowerCase()
                          .includes(searchStr.toLowerCase())
                    )
                    .map((coin) => (
                      <div
                        key={coin.coinSymbol}
                        className={`${styles.listCountry} ${
                          selectCoin?.coinSymbol === coin.coinSymbol &&
                          styles.true
                        }`}
                        onClick={() => {
                          setCoin(coin);
                          onClose();
                        }}
                      >
                        <div className={styles.name}>
                          {coin.coinName}({coin.coinSymbol})
                        </div>
                        <img
                          src={coin.coinImage}
                          alt=""
                          className={styles.flag}
                        />
                      </div>
                    ))}
            </Scrollbars>
          </div>
          <div className={styles.footer}>
            <div
              className={`${styles.btnType} ${type === "fiat" && styles.true}`}
              onClick={() => setType("fiat")}
            >
              Fiat
            </div>
            <div
              className={`${styles.btnType} ${
                type === "crypto" && styles.true
              }`}
              onClick={() => setType("crypto")}
            >
              Crypto
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CoinSelectModal;
