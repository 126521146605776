import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MainLayout } from "../components/FullPageSearch/index";
import Scrollbars from "react-custom-scrollbars";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import ChatsIoComponentM from "../components/ChatsIoComponent/ChatsIoComponent";
import investmentsGroup from "../static/images/logos/investmentsGroup.svg";
import logo from "../static/images/logos/virtualProspectus.svg";
import logoIcon from "../static/images/logos/vpIcon.svg";
import { ChatsContext } from "../context/ChatsContext";
import VirtualProspectusAnalysis from "../components/VirtualProspectusPage/VirtualProspectusAnalysis/VirtualProspectusAnalysis";
import SettingsSelectModal from "../components/SettingsSelectModal/SettingsSelectModal";
import { MainContext } from "../context/MainContext";
import VirtualProspectusVaults from "../components/VirtualProspectusPage/VirtualProspectusVaults/VirtualProspectusVaults";
import MainNavbar from "../layout/MainNavbar/MainNavbar";
import useWindowDimensions from "../Utils/WindowSize";
import VirtualProspectusBusiness from "../components/VirtualProspectusPage/VirtualProspectusBusiness";

function VPPage() {
  const { pathId, tabSelected } = useParams();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { chatOn, setChatOn } = useContext(ChatsContext);
  const {
    mainMenuShow,
    subMenuShow,
    bottomShow,
    tokenSelected,
    setTokenSelected,
    setMainMenuShow,
    setSubMenuShow,
    setBottomShow,
  } = useContext(MainContext);

  const [typesList, setTypesList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [subTypesList, setSubTypesList] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState("");

  const [tab, setTab] = useState("Snapshot");

  useEffect(() => {
    if (tabSelected) {
      setTab(tabSelected);
    } else {
      history.push(`/${pathId}/Snapshot`);
    }
  }, [history, pathId, tabSelected]);

  useEffect(() => {
    !tokenSelected &&
      axios
        .get(
          `https://comms.globalxchange.io/coin/investment/path/get?path_id=${pathId}`
        )
        .then(({ data }) => {
          if (data.status) {
            setTokenSelected(data.paths[0]);
          }
        });
  }, [pathId, setTokenSelected, tokenSelected]);

  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/investment/types/get").then(
      ({ data }) => {
        if (data.status) {
          setTypesList(data.investments);
          data.investments[0] && setSelectedType(data.investments[0]?.code);
        }
      }
    );
  }, []);

  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/coin/investment/sub/types/get?parent_investment_type=${selectedType}`
    ).then(({ data }) => {
      if (data.status) {
        setSubTypesList(data.investmentSubTypes);
        data.investmentSubTypes[0] &&
          setSelectedSubType(data.investmentSubTypes[0].code);
      }
    });
  }, [selectedType]);

  useEffect(() => {
    setMainMenuShow(false);
    setSubMenuShow(false);
    setBottomShow(true);
  }, [setBottomShow, setMainMenuShow, setSubMenuShow]);

  function getContent() {
    switch (tab) {
      case "Snapshot":
        return <VirtualProspectusAnalysis />;
      case "Vaults":
        return <VirtualProspectusVaults />;
      case "Business":
        return <VirtualProspectusBusiness />;
      default:
        break;
    }
  }

  const [settingsModal, setSettingsModal] = useState(false);

  return (
    <MainLayout
      className="landingPage"
      chatOn={chatOn}
      chatComponent={<ChatsIoComponentM />}
    >
      <MainNavbar
        className=""
        logo={width < 768 ? logoIcon : logo}
        onLogoClick={() => history.push("/")}
        chatOn={chatOn}
        setChatOn={setChatOn}
        btIcon={investmentsGroup}
        onBtClick={() => {
          window.open("https://investments.group/", "_blank").focus();
        }}
        btLabel="Investments.Group"
      />
      {mainMenuShow && (
        <Scrollbars className="topBar">
          {typesList?.map((type) => (
            <div
              key={type._id}
              className={`tab ${type.code === selectedType}`}
              onClick={() => setSelectedType(type.code)}
            >
              {type.name}
            </div>
          ))}
        </Scrollbars>
      )}
      {subMenuShow && (
        <Scrollbars className="subBar">
          {subTypesList?.map((type) => (
            <div
              key={type._id}
              className={`tab ${type.code === selectedSubType}`}
              onClick={() => setSelectedSubType(type.code)}
            >
              {type.name}
            </div>
          ))}
        </Scrollbars>
      )}
      <div className="vpPageContent">
        {getContent()}
        {bottomShow && (
          <div className="tabs">
            <div
              className={`tabItem ${tab === "Snapshot"}`}
              onClick={() => {
                setTab("Snapshot");
                history.push(`/${pathId}/Snapshot`);
              }}
            >
              Snapshot
            </div>
            <div
              className={`tabItem ${tab === "Vaults"}`}
              onClick={() => {
                setTab("Vaults");
                history.push(`/${pathId}/Vaults`);
              }}
            >
              Vaults
            </div>
            {tokenSelected?.pathType === "shareToken" ? (
              <div
                className={`tabItem ${tab === "Business"}`}
                onClick={() => {
                  setTab("Business");
                  history.push(`/${pathId}/Business`);
                }}
              >
                Business
              </div>
            ) : (
              <div
                className={`tabItem ${tab === "Trades"}`}
                onClick={() => {
                  setTab("Trades");
                  history.push(`/${pathId}/Trades`);
                }}
              >
                Trades
              </div>
            )}
            <div
              className={`tabItem ${tab === "AboutTheBanker"}`}
              onClick={() => {
                setTab("AboutTheBanker");
                history.push(`/${pathId}/AboutTheBanker`);
              }}
            >
              About The Banker
            </div>
            <div
              className={`tabItem ${tab === "OfferingStatement"}`}
              onClick={() => {
                setTab("OfferingStatement");
                history.push(`/${pathId}/OfferingStatement`);
              }}
            >
              Offering Statement
            </div>
            <div
              className={`tabItem ${tab === "Legal"}`}
              onClick={() => {
                setTab("Legal");
                history.push(`/${pathId}/Legal`);
              }}
            >
              Legal
            </div>
          </div>
        )}
        <div className="btnMenu" onClick={() => setSettingsModal(true)}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
      </div>
      {settingsModal && (
        <SettingsSelectModal onClose={() => setSettingsModal(false)} />
      )}
    </MainLayout>
  );
}

export default VPPage;
