import React from "react";
import { useHistory } from "react-router";

import left from "../../static/images/icons/left.svg";
import TokenDetail from "./TokenDetail";

function SidebarHome({ tokenSelected }) {
  const history = useHistory();
  return (
    <div className="sideBar">
      {tokenSelected?.token_profile_data ? (
        <TokenDetail tokenSelected={tokenSelected} />
      ) : (
        <div className="headbar">
          <div className="value">
            <>
              <img src={left} className="left" alt="" />
              <span>Select Offering</span>
            </>
          </div>
        </div>
      )}
      <div className="buttons">
        <div
          className={`btnComp ${Boolean(tokenSelected)}`}
          onClick={() => {
            window
              .open(
                `https://tokentreasury.com/${tokenSelected?.path_id}`,
                "_blank"
              )
              .focus();
          }}
        >
          Treasury
        </div>
        <div
          className={`btnExch ${Boolean(tokenSelected)}`}
          onClick={() => history.push(`/${tokenSelected?.path_id}`)}
        >
          Explore
        </div>
      </div>
    </div>
  );
}

export default SidebarHome;
