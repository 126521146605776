import React, { Fragment, useEffect, useState } from "react";
import { FormatCurrency } from "../../Utils/Functions";
import CoinSelectModal from "../CoinSelectModal/CoinSelectModal";

function CoinCard({
  segment,
  coin,
  setSegment,
  i,
  coinSelected,
  setModalOutSpace,
}) {
  const [cardCoin, setCardCoin] = useState();
  useEffect(() => {
    setCardCoin(coinSelected);
  }, [coinSelected]);
  const [changeCoin, setChangeCoin] = useState(false);
  useEffect(() => {
    if (changeCoin)
      setModalOutSpace(
        <CoinSelectModal
          setCoin={setCardCoin}
          onClose={() => setChangeCoin(false)}
          coin={
            cardCoin.default
              ? { coinSymbol: coin?.ter_coin.coin_code }
              : cardCoin
          }
        />
      );
    else setModalOutSpace(<Fragment />);
  }, [cardCoin, changeCoin, coin, setModalOutSpace]);
  return (
    <>
      <div
        className={`detailCard ${(!segment && segment !== 0) || segment === i}`}
        style={{ borderLeftColor: coin?.ter_coin.color_code }}
        onClick={() => setSegment(i)}
      >
        <div className="sold">{coin?.ter_coin.coin_name}</div>

        {cardCoin?.default ? (
          <div className="value">
            {FormatCurrency(coin.balance, coin?.ter_coin?.coin_code)}
            {coin?.ter_coin?.coin_code}
          </div>
        ) : (
          <div className="value">
            {cardCoin?.symbol.length < 3 ? cardCoin?.symbol : ""}
            {FormatCurrency(
              coin.usdValue / cardCoin?.usd_price,
              cardCoin?.ter_coin?.symbol
            )}{" "}
            {cardCoin?.symbol.length < 3 ? "" : cardCoin?.symbol}
          </div>
        )}
        <img
          src={coin?.ter_coin?.image}
          alt=""
          className="coin"
          onClick={() => setChangeCoin(true)}
        />
      </div>
    </>
  );
}

export default CoinCard;
